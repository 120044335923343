// Сторонние зависимости
import md5 from 'blueimp-md5';
globalThis.WebSocket = require("websocket").w3cwebsocket;
import { connect, JSONCodec } from '../../node_modules/nats.ws/lib/src/mod.js'

// Vuex
import store from '@/store';

const $ = require('jquery');
const jc = JSONCodec();

function prepareParams(params) {
  const preparedParams = {};

  for (let i = 0, size = params.length; i < size; i++) {
    const param = params[i];
    const type = param.type;

    let value = param.value;

    if (type === 'date' || type === 'dateTime') {
      value = (!!value) ? new Date(value).toISOString() : null;
    }

    const description = {
      '@class': 'ru.kih.sql.Parameter',
      'value': value,
      'type': type,
    };

    if (type === 'blob') {
      const name = param.filename;
      Object.assign(description, { name });
    }

    preparedParams[param.id] = description;
  }

  return preparedParams;
}

/**
 * Формируем параметр даты, который понимает sin2...
 * @param {Date} date
 * @return {Object}
 */

function getDate(date) {
  return {
    '@class': 'ru.kih.sql.Parameter',
    value: date.toISOString(),
    type: 'date',
  };
}

/**
 * Отправка GET-запроса
 * @param {String} sin_url
 * @param {Object} sin_options
 * @return {Promise}
 */
function get(sin_url, sin_options) {
  let options = sin_options;
  let url = sin_url;

  if (typeof url === 'object') {
    options = url;
    url = '/rpc?d=jsonRpc';
  }

  return $.get(url, options);
}

/**
 * Отправка POST-запроса
 *
 * @param {String} sin_url
 * @param {{
 *   type: String,
 *   query: String,
 *   params: Array<{
 *     id: String,
 *     type: String,
 *     value: String|Number|*,
 *   }|*>
 * }} sin_options
 * @return {Promise}
 */
function post(sin_url, sin_options = {}) {
  let options = sin_options || {};
  let url = sin_url;

  if (typeof url === 'object') {
    options = url;
    url = '/rpc?d=jsonRpc';
  }

  const type = (options && options.type) || '';

  // Прокидываем дату
  // Например контроль нарушений не получает явных параметров, он берёт дату из рабочего периода
  if (!!options
    && typeof options === 'object'
    && _hasOwnProperty(options, 'dateWork')
    && !!options.dateWork
  ) {
    options.dateBegin = new Date(options.dateWork.getTime());
    options.dateBegin.setDate(1);
    options.dateEnd = new Date(options.dateWork.getTime());
    options.dateEnd.setMonth(options.dateBegin.getMonth() + 1);
    options.dateEnd.setDate(0);
  } else {
    if (options == null) {
      options = {};
    }

    options.dateBegin = null;
    options.dateWork = null;
    options.dateEnd = null;
  }

  const defaultContext = {
    dateBegin: getDate(options?.dateBegin || store.getters['period/begin']),
    dateWork: getDate(options?.dateWork || store.getters['period/current']),
    dateEnd: getDate(options?.dateEnd || store.getters['period/end']),
  };

  let config = {
    dataType: 'json',
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
  };

  switch (type) {
    case 'core-read':
      config.data = {
        method: 'ru.kih.sin.api2.Core.read',
        params: [
          {
            query: options.query,
            context: options.context || defaultContext,
          },
        ],
        jsonrpc: '2.0',
      };

      break;

    case 'core-create':
      config.data = {
        method: 'ru.kih.sin.api2.Core.create',
        params: [{
          query: options.query,
          context: options.context || defaultContext,
          params: prepareParams(options.params),
          offset: 0,
          count: -1,
        }],
        jsonrpc: '2.0',
      };

      break;

    case 'core-update':
      config.data = {
        method: 'ru.kih.sin.api2.Core.update',
        params: [{
          query: options.query,
          context: options.context || defaultContext,
          params: prepareParams(options.params),
          offset: 0,
          count: -1,
        }],
        jsonrpc: '2.0',
      };

      break;

    case 'core-delete':
      config.data = {
        method: 'ru.kih.sin.api2.Core.delete',
        params: [
          {
            query: options.query,
            context: options.context || defaultContext,
            params: prepareParams(options.params),
          },
        ],
        jsonrpc: '2.0',
      };

      break;
    case 'tree-childs':
      config.data = {
        method: 'ru.kih.sin.api2.Tree.getChilds',
        params: [{
          query: options.query,
        }],
        jsonrpc: '2.0',
      };

      break;
    case 'core-report-info':
      config.data = {
        method: 'ru.kih.sin.reports.ReportsService.getReportDescription',
        params: { arg0: options.name },
        jsonrpc: '2.0',
      };

      break;

    case 'auth':
      config.data = {
        method: 'web.AuthFunctions.getCurrentUserCredential',
        jsonrpc: '2.0',
      };

      if (options.basicAuth) {
        config.beforeSend = function(xhr) {
          xhr.setRequestHeader('Authorization', options.basicAuth);
        };
      }

      break;

    case 'logout':
      config.data = {
        method: 'web.Users.logout',
        jsonrpc: '2.0',
      };

      break;
    case 'query':
      config.data = {
        method: 'ru.kih.sin.api2.NamedQueries.read',
        params: [options.query, options.params],
        jsonrpc: '2.0',
      };

      break;

    case 'query-update':
      config.data = {
        method: 'ru.kih.sin.api2.NamedQueries.update',
        params: [options.query, options.params],
        jsonrpc: '2.0',
      };

      break;

    default:
      config = Object.assign(config, options);
  }

  if (_hasOwnProperty(config, 'data') && typeof config.data === 'object') {
    config.data = JSON.stringify(config.data);
  }

  return $.ajax(url, config);
}

function postPublicApi(url, postData) {
  return $.ajax({
    url: url,
    method: 'post',
    dataType: 'json',
    contentType: 'application/json;charset=utf-8',
    data: postData,
  });
}

async function getNats(wsUri) {
  var conn = null;
  try {
    conn = await connect(wsUri);
  } catch(e){
    console.log('ERR (getNats):', e);
  }

  return conn;
}

function getJSONCodec() {
  return jc;
}

/**
 * Обработка ответа от sin
 * Проверяет что данные не пусты и формируем
 *
 * Описание options
 *
 * ```
 * {
 *   keyReplace - Ключ - как в ответе, значение - какой ключ сделать,
 *   ignoreKeys - Ключи которые необходимо проигнорировать
 *   hideUnderscores - Скрыть поля начинающиеся на _
 * }
 * ```
 *
 * @param {Object} response Ответ от сервера
 * @param {{
 *   keyReplace: Object|*
 *   ignoreKeys: Array<String>|*
 *   hideUnderscores: Boolean|*
 * }} options Дополнительные опции
 * @return {Array<Object>} Массив преобразованных объектов
 */
function prepareSinResponse(response, options = {}) {
  if (!!response && response.result) {
    const responseResult = response.result || {};
    const items = responseResult.data || [];

    // Когда мы читаем не коллекцию (core-read), а используем именованный запрос (query)
    let ci = responseResult.columnIndexes;

    if (!ci) {
      ci = {};

      (responseResult.fields || []).forEach((it, index) => {
        ci[it] = index;
      });
    }

    const keys = Object.keys(ci);

    const keyReplace = options.keyReplace || {};
    const ignoreKeys = options.ignoreKeys || [];
    const hideUnderscores = options.hideUnderscores || false;

    return items.map(it => {
      const result = {};

      keys.forEach(key => {
        // Если данный ключ необходимо пропустить
        if (ignoreKeys.includes(key)) {
          return;
        }

        // Значение
        const value = it[ci[key]];
        const shortKey = key.substring(key.indexOf('.') + 1);

        // Если ключ необходимо подменить
        if (_hasOwnProperty(keyReplace, key) || _hasOwnProperty(keyReplace, shortKey)) {
          const newKey = keyReplace[key] || keyReplace[shortKey];

          result[newKey] = value;
        } else if (key.substring(0, 1) === '_') {
          // Если системный
          if (!hideUnderscores) {
            // Если передали параметр, то оставляем как есть
            result[key] = value;
          }
        } else {
          let k = shortKey;

          if (shortKey.indexOf('.') > -1) {
            k = shortKey.split('.')
              .map(it => it[0].toUpperCase() + it.slice(1))
              .join('');

            k = k[0].toLowerCase() + k.slice(1);
          }

          result[k] = value;
        }
      });

      return result;
    });
  }

  return [];
}

/**
 * Подготовка запроса к sin
 *
 * @param {String} type core-read|update|delete
 * @param {String} query Строка запроса
 * @param {String} prefix Префикс для полей
 * @param {Array<String>} fields Поля которые необходимо получить
 * @param {Array<Object>|Object} params Параметры передаваемые для именованных запросов, core-create, core-delete
 * @param {{
 *   cache: Boolean,
 * }} config Дополнительный конфиг
 * @return {Promise<Object>}
 */
function prepareSinRequest(
  type,
  query,
  prefix = '',
  fields = [],
  params = {},
  config = {
    cache: false,
  },
) {
  return new Promise((resolve) => {
    const cacheKey = md5(type + query);
    const cacheData = store.getters['cache/getItem'](cacheKey);

    if (cacheData !== null) {
      resolve(cacheData);

      return;
    }

    if (fields.length) {
      const isAmp = query.indexOf('?') > -1;

      query += `${isAmp ? '&' : '?'}fields=${fields.map(it => `${prefix}.${it}`).join(',')}`;
    }

    resolve(
      post('/rpc?d=jsonRpc', {
        type: type,
        query: query,
        params: params,
      }).then(async response => {
        if (config.cache) {
          await store.dispatch('cache/setItem', {
            key: cacheKey,
            value: response,
          });
        }

        return response;
      }),
    );
  });
}

/**
 * Формирование параметра http
 *
 * @param {String} name Название поля
 * @param {String} type Тип поля
 * @param {String|Number|*} value Значение поля
 * @return {{
 *    id: String,
 *    type: String,
 *    value: String|Number|*,
 * }}
 * @private
 */
function httpParam(name, type, value) {
  return {
    id: name,
    type: type,
    value: value,
  };
}


function sin2meta(ci /*columnIndexes*/, model) {
  const cols = [...model.columns.values()];
  var meta = [];
  cols.map((col) => {
    var name = col.id.toLowerCase();
    var n = ci[name];
    if (!(!!n)) {
      return;
    }
    meta.push({
      name: name,
      field: name.replaceAll('.', '_'),
      title: col.title,
      n: n,
      t: col.typeName,
      size: col.size,
    });
  });
}

// Экспортируем публичные методы
export {
  get,
  post,
  getNats,
  getJSONCodec,
  prepareSinResponse,
  prepareSinRequest,
  httpParam,
  sin2meta,
  postPublicApi,
};
