<script>
export default {
  props: {
    type: {
        type: String,
        required: true,
        default: 'unknown'
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: false
    },
    multiline: {
      type: Boolean,
      default: false
    },
    uri: {
      type: String,
      default: null
    },
    editorUri: {
      type: String,
      default: null
    },
    noactions: {
        type: Boolean,
        default: false
    },
    visible: {
        type: Boolean,
        default: true
    }
  },
  inject: ['owner'],
  data() {
    return {
      valid: false,
      rules: [],
      isFocused: false,
      vis: this.visible,
      dis: this.disabled
    };
  },
  computed: {
    hasLabel() {
      return this.label !== '';
    },
    hasValue() {
      return !!this.value;
    },
    hasPlaceholder() {
      return this.placeholder !== '';
    },
    name(){
        return this.$attrs.hasOwnProperty('name') 
               ? this.$attrs.name
               : (!!this.$vnode) 
                    ? this.$vnode.data.model.expression 
                    : 'unknown';
    }
  },
  watch: {
    value(val){
        if (!!this.owner) {
            this.owner.val(this.name, val);
        }
        this.$emit('input', val);
        this.validate();
    }
  },
  created() {
    if (!!this.owner) {
      this.owner.reg(this);
    }
    if (this.required) {
      this.rules.push((v) => !!v || 'Обязательное поле');
    }
  },
  beforeDestroy() {
    if (!!this.owner) {
      this.owner.unreg(this);
    }
  },
  methods: {
    setVisible(val) {
      this.vis = val;
    },
    setRequired(val) {
      var rules = this.rules;
      const type = this.type;
      if ( val ) {
        if ( type == 'boolean' ) {
          rules.push(function required(v) {return (v !== null) || 'Обязательное поле'});
        } else {
          rules.push(function required(v) {return !!v || 'Обязательное поле'});
        } 
      } else {
        rules.map((rule, i) => {
          if ( rule.name == "required" ) {
            rules.splice(i, 1);
          }
        });
      }
      this.rules = rules;
    },
    setDisabled(val) {
      this.dis = val;
    },
    onFocus(e) {
      this.isFocused = true;
    },
    onBlur(e) {
      this.isFocused = false;
    },
    validate() {
      const {value, rules} = this;
      const errors = [];

      for (let i = 0, size = rules.length; i < size; i++) {
        const rule = rules[i];
        const result = rule(value);

        if (typeof result === 'string') {
          errors.push(result);
          break;
        }
      }

      this.valid = errors.length === 0;

      return this.valid;
    }
  }
};
</script>
