import router from '@/router';
import store from '@/store';

const state = {
    subject: null,
    id: null,
    name: null,
    tenant: null,
    title: null,
    countAttempts: $utils.readLocalStorage('auth', 'countAttempts') || 0,
    lastAction: $utils.readLocalStorage('auth', 'lastAction') || Date.now(),
    countAttemptsLimit: 3,
    timeout: 5 // в минутах
};

const mutations = {
    setSubject(state, payload) {
        state.subject = payload;
        store.commit('auth/setSubject', payload); //for backward's
        if (payload) {
            state.id = payload.id;
            state.name = payload.name;
            state.tenant = payload.tenantId;
            state.title = payload.title;
        } else {
            state.id = null;
            state.name = null;
            state.tenant = null;
            state.title = null;
        }
        jet.on('subject');
    },
    incrementCount(state) {
        if (state.countAttempts >= state.countAttemptsLimit) {
            state.countAttempts = 1;
        } else {
            state.countAttempts += 1;
        }
        state.lastAction = Date.now();
        $utils.saveLocalStorage('auth', {
            countAttempts: state.countAttempts,
            lastAction: state.lastAction
        });
    },
    resetCount(state) {
        state.countAttempts = 0;
        $utils.saveLocalStorage('auth', {
            countAttempts: state.countAttempts
        });
    }
};

const actions = {
    login(store, payload) {
        const { commit, dispatch } = store;
        const { remember, user } = payload;
        const promise = (resolve, reject) => {
            /**
             * Action on success
             * @param {Object} res
             */
            function onSuccess(res) {
                commit('resetCount');
                if (res.result) {
                    commit('setSubject', res.result);
                    dispatch('profile/init', res.result, { root: true });
                    dispatch('setAuthorization', remember ? basicAuth : null);
                    resolve(res);
                } else {
                    commit('setSubject', null);
                    reject(res.error);
                }
            }
            /**
             * Action on error
             * @param {Object} err
             */
            function onFail(err) {
                commit('incrementCount');
                commit('setSubject', null);
                reject(err);
            }
            var basicAuth = 'Basic ' + btoa(user.login + ':' + user.password);
            const options = {
                type: 'auth',
                basicAuth: basicAuth
            };
            const url = '/rpc?d=jsonRpc';
            $http
                .post(url, options)
                .then(onSuccess)
                .catch(onFail);
        };
        return new Promise(promise);
    },
    check: async store => {
        const { commit, dispatch } = store;
        const promise = (resolve, reject) => {
            /**
             * Action on success
             * @param {Object} res
             */
            function onSuccess(res) {
                if (res.result) {
                    commit('setSubject', res.result);
                    dispatch('init', res.result);
                    dispatch('setAuthorization', basicAuth);
                    resolve(res);
                } else {
                    commit('setSubject', null);
                    reject(res.error);
                }
            }
            /**
             * Action on error
             * @param {Object} err
             */
            function onFail(err) {
                commit('setSubject', null);
                reject(err);
            }
            var basicAuth = $utils.readLocalStorage('auth', 'auth') || null;
            const options = {
                type: 'auth',
                basicAuth: basicAuth
            };
            const url = `/rpc?d=jsonRpc`;
            $http
                .post(url, options)
                .then(onSuccess)
                .catch(onFail);
        };
        return new Promise(promise);
    },
    logout(store) {
        const { commit, dispatch } = store;
        const promise = resolve => {
            /**
             * Action on success
             * @param {Object} res
             */
            function onSuccess(res) {
                commit('setSubject', null);
                dispatch('setAuthorization', null);
                dispatch('clearCookies');
                resolve(res);
                router.push('/login');
            }
            /**
             * Action on error
             * @param {Object} err
             */
            function onFail(err) {
                commit('setSubject', null);
                dispatch('setAuthorization', null);
                dispatch('clearCookies');
                resolve({ error: err });
            }
            const options = {
                type: 'logout'
            };
            const url = `/rpc?d=jsonRpc`;
            $http
                .post(url, options)
                .then(onSuccess)
                .catch(onFail);
        };
        return new Promise(promise);
    },
    setAuthorization(store, basicAuth) {
        $utils.saveLocalStorage('auth', { auth: basicAuth || null });
    },
    clearCookies() {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    },
    init(auth) {
        var user = null;
        var newUser = true;
        var profiles = $utils.readLocalStorage('profiles');
        if (!profiles) {
            profiles = [];
        }
        for (var i = 0; i < profiles.length; i++) {
            if (profiles[i].id === auth.id) {
                profiles[i].attrs = auth.attrs;
                profiles[i].availableTenants = auth.availableTenants;
                profiles[i].groups = auth.groups;
                profiles[i].id = auth.id;
                profiles[i].name = auth.name;
                profiles[i].primaryGroupId = auth.primaryGroupId;
                profiles[i].roles = auth.roles;
                profiles[i].settings = auth.settings;
                profiles[i].tenantId = auth.tenantId;
                profiles[i].tenants = auth.tenants;
                profiles[i].title = auth.title;
                newUser = false;
                user = profiles[i];
                break;
            }
        }
        if (newUser) {
            user = {
                attrs: auth.attrs,
                availableTenants: auth.availableTenants,
                groups: auth.groups,
                id: auth.id,
                name: auth.name,
                primaryGroupId: auth.primaryGroupId,
                roles: auth.roles,
                settings: auth.settings,
                tenantId: auth.tenantId,
                tenants: auth.tenants,
                title: auth.title
            };
            profiles.push(user);
        }
        $utils.saveLocalStorage('profiles', profiles);
    }
};

const getters = {
    isAuthenticated(state) {
        return (!!state.subject)&&(!/^$/.test(state.subject.id));
    },
    getName(state) {
        return state.title;
    },
    getID(state) {
        return state.id;
    },
    timeout(state) {
        const {
            countAttempts,
            lastAction,
            countAttemptsLimit,
            timeoutDuration
        } = state;
        const timeoutDurationMs = timeoutDuration * 60 * 1000;
        const countOver = countAttempts >= countAttemptsLimit;
        const timeOver = Date.now() - lastAction >= timeoutDurationMs;
        return countOver && !timeOver;
    },
    subject(state){
        return state.subject;
    },
    orgName(state){
        if (!!state.subject.tenantId){
            return (!!state.subject)&&(!!state.subject.tenants)
                        ? state.subject.tenants[state.subject.tenantId].title
                        : '';
        } else {
            return '';
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
