<script>
import JetInput from '@/components/editors/JetInput';
//import JetFiles from '@/components/JetFiles';
//import JetEditTable from '@/components/JetEditTable';
import Model from '@/core/Model';

class JetOwnProxy {
    controls = []
    constructor(conte){
        this._own = conte;
    }
    reg(comp){
        this.controls.push(comp);
        console.log('form-register: ', comp);
    }
    unreg(comp){
        console.log('form-unregisted: ', comp);
    }
    reglink(comp) {
        console.log('form-register-link: ', comp);
        this._own.links.push(comp);
    }
    unreglink(comp) {
        console.log('form-register-link: ', comp);
        var i = this._own.links.indexOf(comp);
        if ( i !== -1 ) {
          this._own.links.splice(i, 1);
        }
    }
    val(name, val){
        console.log('val:', name, val);
        this._own.values[name] = val;
    }
}   //JetFormProxy

export default {
    name: 'JetEditConte',
    props: {
        /** 
         * uri for sin2-view
        */
        uri: { 
            type: String,
            required: false,
            default: 'xxx$$$xxx'
        },
        /**
         * Object (id & etc) for editing
         */
        id: {
            type: String,
            required: false
        },
        add: {            
            type: Boolean,
            required: true,
            default: true
        },
        temporary: {
            type: Boolean,
            default: false
        }
    },
    data: function(){
        return {
            model: null, /*sin2-based model*/
            raw:   null, /*sin2 raw-data*/
            loading: false,
            form:  null,
            error: null,
            tabIdx: 0,
            values: {},
            links: [],
            v: null
        };
    },
    created(){
        this.do_edit();
    },
    computed:{
        value: {
            get: function() {
                return this.v;
            },
            set: function(val) {
                this.v = val;
            }
        },
        _add(){
            return !(
                        (!this.add) 
                      &&(!!this.raw)
                      &&(this.raw.length > 0)
                    );
        }
    },
    provide: function(e) {
        var self = this;
        return {
            objValue: (id) => {
                self.value = id;
            }
        }
    },
    methods:{
        async loadModel(){
            const self = this;
            var uri = this.uri;
            this.value = this.id;
            if ( "/" !== uri.charAt(uri.length-1) ){
                uri += "/";
            }
            uri += "?id=" + ((!!this.id) ? this.id : "null");
            try {
                var resp = await $http.post('/rpc?d=jsonRpc', {
                    type: 'core-read',
                    query: uri
                });
                if (!!resp.error){
                    throw resp.error;
                }
                this.model = new Model(resp.result.model);
                this.raw   = resp.result.data;
                var meta = await this.model.get_meta();  //for form reading & etc
                
                var form = this.model.form;
                if (!!form){
                    form = (new Function(form))();
                    if (!form.hasOwnProperty("components")){
                        form.components = {};
                    }
                    form.components['JetInput'] = JetInput;
                    //form.components['JetFiles'] = JetFiles;
                    //form.components['JetEditTable'] = JetEditTable;
                    if (!form.hasOwnProperty("computed")){
                        form.computed = {};
                    }
                    form.computed["tab"] = {
                        get: ()=>{ return self.tabIdx; },
                        set: (val)=>{ self.tabIdx = val; }
                    };
                    if (!form.hasOwnProperty("provide")){
                        form.provide = {};
                    }
                    form.provide['owner'] = new JetOwnProxy(this);
                    form.inject = ['objValue'];
                    form.data = this.getValues;
                    this.form = form;
                }
            } catch(e){
                this.error = e;
                console.log(e);
            }
        },
        do_edit(){
            this.loading = true;
            this.loadModel();
            this.loading = false;
        },
        getValues(){
            this.values = {};
            if (!!this.model){
                var ci = this.model.columnIndexes,
                    fAdd = this._add;
                var ids = Object.keys(ci);
                ids.map((i)=>{
                    this.values[i] = (fAdd) ? null : this.raw[0][ci[i]];
                });
            } 
            return this.values;
        },
        async save(fixOnly){
            if ( !this.$refs.form.validate() ) {
                return;
            }
            if ( this.temporary ) {
                this.$emit('save', this.values);
                this.$emit('hide');
                //return;
            }
            var data = this.values,
                id   = (this.value) ? this.value : $utils.uuidv4(), //this._add ? $utils.uuidv4() : this.id,
                opts = {
                    type: this._add ? 'core-create' : 'core-update',
                    query: 'sin2:/v:' + this.model.id,
                    params: [
                        {id: 'id', type:'id', value: id}
                    ]
                };
            this.model.params.map((item)=>{
                opts.params.push({id: item._id, type: item.type, value: data[item.name]});
            });
            console.log('saving:', opts, this.model);
            try {
                var res = await $http.post(opts);
                console.log('SAVE res:', res);
                if (!!res.error){
                    throw res.error;
                }
                this.$emit('data-change', {add: this._add, id: id});
                if (!fixOnly){
                    this.$emit('hide');
                }
                this.links.map((l) => {
                    res = l.save(id);
                })
            } catch(e){
                console.log('SAVE ERR:', e);
                jet.msg({text:'ВНИМАНИЕ! Ошибка сохранения изменений. <br /><small>Информация для технической поддержки: ' + 
                         e.message + '</small>', type:'warning'});
            }
        }
    },
    render: function(h){
        return h('v-card', {
                class: {'jet-edit-conte': true}, 
                props: {
                            flat: true, 
                            loading: this.loading,
                            width: '100%'
                }
            }, [
            h('v-card-title',[
                (!!this.model) ? this.model.name : ''
            ]),
            h('v-card-subtitle', [
                (this._add) ? 'добавление' : 'редактирование'
            ]),
            (!!this.form) 
                ? h('v-card-text', [
                        h('v-form', {ref: 'form', class: {'jet-form': true}}, [
                            h(this.form)
                        ])
                    ])
                : null, //TODO: when form is`t avail
            h('v-card-actions',[
                h('v-btn', {
                    props: {outlined:true, tile:true, color: 'primary', small: true},
                    on: {click: ()=>{this.save(false);}}
                }, 'Записать'),
                h('v-btn', {
                    props:{outlined:true, tile:true, color: 'gray', small: true},
                    on: {click: ()=>{this.save(true);}}
                }, 'Применить'),
                h('v-btn', {
                    props:{outlined:true, tile:true, color: 'gray', small: true},
                    on: {click:()=>{this.$emit('hide');}}
                }, 'Отменить')
            ])
        ]);
    }   //render
}
</script>
<style lang="scss">
    .v-card.jet-edit-conte{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%; 
        & .v-card__title{
            padding-bottom: 12px;
            padding-top: 4px;
            line-height: 1.115;
        }
        & .v-card__subtitle{
            padding-bottom: 0;
        }
        & .v-card__text{
            height: 100%;
            max-height: calc(100% - 120px);
            & .jet-form{
                overflow-y: scroll;
                height: 100%;
                & .v-tabs{
                    height: 100%;
                    & .v-window.v-tabs-items{
                        height: 100%;
                        overflow: auto;
                        padding: 8px 16px 32px 16px;
                    }
                }
            }
        }
        & .v-card__actions{
            padding: 16px;
            justify-content: flex-end;
        }
    }
</style>