<template>
  <div class="jet-input jet-input-int" v-show="vis">
        <v-text-field
          type="text"
          v-model="value"
          :disabled="dis"
          :rules="rules"
          :hidden="hidden"
          :label="label"
          @input="change"
          >
            <template v-slot:append-outer>
                <jet-input-btn @clear="clear()" />
            </template>
        </v-text-field>
  </div>
</template>

<script>
import JetInputBase from '@/components/editors/JetInputBase';
import JetInputBtn from '@/components/editors/JetInputBtn';

export default {
    name: 'JetInputInteger',
    extends: JetInputBase,
    components: {
        JetInputBtn
    },
    data() {
        return {
            old: this.$attrs.value,
            value: null
        };
    },
    created(){
        this.value = this.$attrs.value;
    },
/*    
    computed: {
        value: {
            get(){
                return this.$attrs.value;
            },
            set(val){
                console.log('JetInputInteger:', val);
                this.$attrs.value = val;
                this.$emit('input', val);
                if (!!this.owner) {
                    this.owner.val(this.name, val);
                }
            }
        }
    },
* 
*/
    methods: {
        change(e){
            var n = (!!e) ? Number(e.replace(/[^0-9]+/g, '')) : 0;
            if (Number.isNaN(n)){
                n = null;
            }
            this.value = n;
        },
        clear(){
            this.value = null;
        }
    }
};
</script>
<style lang="scss" scoped>
    .jet-input-int{
        max-width: 16rem;
    }
</style>