<template>
  <div class="jet-input jet-input-date" v-show="vis"
       :style="{'max-width': (hasTime) ? '18rem' : '14rem'}">
        <v-text-field
            v-model="text"
            :label="label"
            :disabled="dis"
            :rules="rules"
            :hint="'формат: ' + fmt"
            persistent-hint
            :hidden="hidden"
            @input="on_input($event)"
            @blur="on_blur($event)"
            :error="!valid"
            ref="dtinput">
            <template v-slot:append>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  min-width="290px"
                  ref="dtmenu"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          text
                          small
                          tile
                        ><svg viewBox="0 0 448 512"><use xlink:href="#ico-calendar" /></svg>
                        </v-btn>
                    </template>
                    <v-date-picker
                        v-model="date"
                        no-title
                        scrollable
                        @input="menu = false"
                        :first-day-of-week="1"
                        :weekday-format="dayOfWeekToFormat"
                    ></v-date-picker>
                </v-menu>
            </template>
            <template v-slot:append-outer>
                <jet-input-btn v-on:clear="date = null" />
            </template>
        </v-text-field>
  </div>
</template>

<script>
import JetInputBase from '@/components/editors/JetInputBase';
import JetInputBtn from '@/components/editors/JetInputBtn';
import {dayOfWeekToFormat} from "@/services/JetDate";
import Inputmask from "inputmask";

export default {
  name: 'JetInputDate',
  extends: JetInputBase,
  data() {
    return {
      menu: false,
      hTimer: false,
      value: this.$attrs.value,
      text: '',
      dayOfWeekToFormat: () => ''
    };
  },
  components: {
      JetInputBtn
  },
  created() {
    const _m = this.$moment(this.value);
    if (_m.isValid()){
        this.text = _m.format(this.fmt);
    }
    this.dayOfWeekToFormat = dayOfWeekToFormat;
  },
  mounted(){
      this.valid = this.validate(this.value);
      this.$nextTick(()=>{
          const mask = (this.hasTime) ? "99.99.9999 99:99:99" : "99.99.9999";
          const node = $(this.$el).find('input');
          Inputmask({"mask": mask}).mask(node.get(0));
      });
  },
  computed: {
    hasTime(){
        return /time+$/i.test(this.type);
    },
    fmt(){
        return (this.hasTime) ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY';
    },
    /*
     * get/set val to date-picker
     * 
     */
    date: {
        get: function(){
            var d = this.$moment(this.value);
            if (d.isValid()){
                return d.toISOString().substr(0, 10);  //date only yyyy-mm-dd for picker
            }
            return null;
        },
        set: function(val){
            if (!!val){
                var a = val.split('-');
                this.value = new Date(a[0], a[1]-1, a[2]);
                this.text  = this.$moment(this.value).format(this.fmt);
            } else {
                this.value = null;
                this.text  = '';
            }
            this.validate(this.value);
            
            this.$nextTick(()=>{
                $(this.$el).find('input').trigger('focus');
            });
        }
    }
  },
  methods: {
        validate(val){
            if (!!val){
                this.valid = this.$moment(val).isValid();
            } else {
                this.valid = (this.required) ? false : true;
            }
            return this.valid;
        },
        _set_a_val(val){
            if (this.hTimer){
                clearTimeout(this.hTimer);
                this.hTimer = false;
            }
            if ($utils.isEmpty(val)){
                this.value = null;
            } else {
                this.hTimer = setTimeout(()=> {
                    const _m = this.$moment(val, this.fmt);
                    if ( _m.isValid() ){
                        this.value =  _m.toDate();
                        this.valid = true;
                    } else {
                        this.valid = false;
                    }
                    this.hTimer = false;
                }, 900);
            }
        },
        on_input(val){
            this._set_a_val(val);
        },
        on_blur(e){
            this.validate(this.value);
            this.$emit('input', this.value);
        }
    }
};
</script>

<style lang="scss" scoped>
    .jet-input-date{
        & .v-btn{
            width: 32px;
            height: 32px;
            min-width: 32px;
            & svg{
                width: 16px;
                height: 16px;
            }
        }
    }
</style>
