<script>
const $moment = require('moment');
import { MODES } from "@/utils";

const _stop_e = (e)=>{
    if (!!e){
        e.preventDefault();
        e.stopPropagation();
    }
};

const formatters = {
    "date": (val)=>{
        return (!!val) ? $moment(val).format('DD.MM.YYYY') : null;
    },
    "datetime": (val)=>{
        return (!!val) ? $moment(val).format('DD.MM.YYYY hh:mm:ss') : null;
    },
    "boolean":  (val, h)=>{
        return h('v-simple-checkbox', {
                  domProps: { checked: !!val },
                  props: { disabled: true, value: !!val }
        });
    }
};

class TbResizer {
    constructor(comp){
        this.el = comp.$el;
        this.end = comp.onresize;
        this.mode = comp.mode;
        this.init();
    }
    static stop(e){
        e.stopPropagation();
        e.preventDefault();
    }
    init(){
        const tb = $(this.el).find('table'),
            _end = this.end;
        
        if ('single' !== this.mode){
            tb.css({width: (tb.outerWidth() + 200) + 'px', tableLayout: 'fixed'});
        }

        var resizer, pageX, col, startWidth, width;
        
        document.addEventListener('mousemove', function(e){
            if (!!col) {
                TbResizer.stop(e);
                var diffX = e.pageX - pageX;
                width = startWidth + diffX;
                resizer.css({left: width + "px" });
            }
        });
        document.addEventListener('mouseup', function(e){ 
            if (!!col){
                TbResizer.stop(e);
                col.css({width: width + "px"});
                col.removeClass("moving");
                resizer.css({left: "", right: "-2px" });
                _end({n: col.index(), w: width});
            }
            col = undefined;
            pageX = undefined;
            startWidth = undefined;
        });
        
        tb.find('.v-data-table-header tr th').map(function(){
            var div = $(this).find('.jet-resizer');
            div.on('dblclick', function(e){
                var col = $(e.target).parent();
                if (!!col) {
                    col.css({width: ''});
                    _end({n: col.index()});
                }
            });
            div.on('click', this.stop);
            div.on('mousedown', function(e){
                pageX = e.pageX;
                resizer = $(e.target);
                col = resizer.parent();
                col.addClass("moving");
                startWidth = col.width();
            });
        });
    }       //init
    /**
     * @param {n: Integer, w: Integer} attrs for changed col-params
     */
    end(e){} //stub
};      //TbResizer

export default {
    name: 'JetTable',
    props: {
        mode: {
            type: String,
            required: true,
            default: 'single' /* single|master|detail */
        },
        loading: {
            type: Boolean,
            required: true
        },
        model: {
            type: Object,
            required: true
        },
        focused: {
            type: Boolean,
            required: true,
            default: false
        },
        search: {
            type: String,
            required: false
        },
        rows: {
            type: Array,
            required: true
        },
        value: {
            type: String,
            required: false,
            default: null
        }
    },
    data(){
        return {
            MODES,
            state: MODES.none,
            selected: null,
            page: undefined
        };
    },
    computed: {
        headers(){
            return (!!this.model) ? this.model.columns : [];
        },
        selId: {
            get(){
                return (!!this.selected) ? this.selected[this.model.columnId] : null;
            },
            set(id){
                this._ready(true).then(()=>{
                    const keyId = this.model.columnId;
                    var n = this.rows.findIndex((r)=>{ return ( r[keyId]===id ); });
                    if ( n > -1 ){
                        this.selected = this.rows[n];
                        this._go(n);
                        this.$emit('change', id);
                    } else {
                        this.selected = null;
                    }
                }).catch((e)=>{
                    console.log(`No ${id} found`, e);
                });
            }
        },
        selName(){
            if (!!this.selected){
                var a = [];
                this.headers.map((h)=>{
                    if (h.asName){
                        a.push(this.selected[h.id]);
                    }
                });
                return a.join(' ');
            } else {
                return '';
            }
        }
    },
    mounted(){
        this.$nextTick(()=>{
            $(this.$el).find('.v-data-footer .v-data-footer__select').before('<div class="jet-name-item"></div><div class="spacer"></div>');
            new TbResizer(this);
            $(this.$el).keydown((e)=>{
                if ( 
                        (e.key === 'ArrowUp') 
                      ||(e.key === 'ArrowDown') 
                   ){
                    _stop_e(e);
                    this._move(e.key === 'ArrowUp');
                    return false;
                } 
                if ( e.key === 'Enter' ){
                    $(this.$el).find('.v-data-table__selected').click();
                }
            });
        });
    },
    methods: {
        /**
         * Wait ready of table 
         * @param {Boolean} rows - wait row`s loaded
         * @returns {Promise}
         */
        async _ready(rows){
            return new Promise((resolve, reject)=>{
                var n = 0;
                const _wai = ()=>{
                    var ok = false;
                    n++;
                    if ( n > 5000 ){
                        reject();
                    } else if (!!this.model) {
                        ok = (!!rows) ? (this.rows?.length > 0) : true;
                    }
                    if (ok){
                        resolve();
                    } else {
                        setTimeout(_wai, 200);
                    }
                };  // _wai...
                _wai();
            });
        },
        _go(index){
            const $vuetify = this.$vuetify,
                  el = $(this.$el),
                  table = this.$refs["table"];
            try {
                var pp = table._props.itemsPerPage;
                this.page = ((index - index % pp) / pp) + 1;
            } catch(e){}
            this.$nextTick(function(){
                table.select(this.rows[index]);
                this.$nextTick(function(){
                    const w = ("single" === this.mode) ? el.parent() : el.find('.v-data-table__wrapper');
                    const r = el.find('.v-data-table__selected');
                    if (r.length > 0){
                        $vuetify.goTo(r[0], {container: w[0]});
                    }
                });
            });
            
        },
        _move(up){
            const table = this.$refs["table"];
            const rows = table.customFilterWithColumns(this.rows, this.search);
            if (rows.length < 1){
                return false;
            }
            
            var n, current = (!!this.selected) ? this.selected : rows[0];
            
            n = rows.findIndex((r)=>{
                return r[this.model.columnId] === current[this.model.columnId];
            }) + ( (up) ? -1 : 1);
            if ( n < 0 ){
                n = 0;
            } else if ( n >= rows.length ){
                n = rows.length-1;
            }
            this._go(n);
        },
        onresize(e){
            const hdrs = this.headers;
            const h = hdrs[e.n];
            h.width = (!!e.w) ?  e.w + "px" : "auto";
        },
        _selTbItem(item){
            var id = null;
            if (item.value) {
                id = item.item[this.model.columnId];
                this.selected = item.item;
            } else {
                this.selected = null;
            }
            this.$emit('change', id);
            this.$emit('cb_change', this.selected);
            if (this.mode === 'master'){
                $(this.$el).find('.v-data-footer .jet-name-item').html(this.selName);
            }
        },
        focus(){
            if (!!this.$el){
                $(this.$el).focus();
                if ( !(!!this.selected) ){
                    this._move(true); //move first
                }
            }
        }
    },
    watch: {
        value(id){
            this.selId = id;
        }
    },
    render(h){
        var slots = {};
        this.headers.map((hdr)=>{
            if (hdr.asName){
                hdr.class = "named";
            }
            slots['header.' + hdr.id] = ()=>{
                return [
                    h('v-tooltip', {
                        props: {bottom: true},
                        scopedSlots: {
                            activator: ({on}) => {
                                return h('div', {class: "jet-title", on: on}, hdr.title);
                            }
                        }
                    }, hdr.title),
                    h('div', {class:"jet-resizer"})
                ];
            };
            const _f = formatters[hdr.type.toLowerCase()];
            if (!!_f){
                slots['item.' + hdr.id] = (item)=>{
                    return _f(item.value, h);
                };
            }
        });
        const isMaster = (this.mode === 'master');
        return h('v-data-table', {
            props: {
                "loading": this.loading,
                "headers": this.headers,
                "fixed-header": true,
                "items": this.rows,
                "item-key": this.model.columnId,
                "multi-sort": true,
                "single-select": true,
                "show-select": false,
                "search": this.search,
                "disable-pagination": !isMaster,
                "hide-default-footer": !isMaster,
                "page": this.page,
                "footer-props": {
                    "items-per-page-options": [30, 50, 500, -1]
                },
                "header-props": {
                    "sort-icon": "mdi-arrow-down-bold-circle-outline"
                },
                "items-per-page": 50
            },
            ref: "table",
            attrs: {
                tabindex: "3"
            },
            class: {"fill-height": true, "jet-table": true},
            scopedSlots: slots,
            on: {
                    "click:row": (item, row) => {
                        row.select(true);
                        this.$parent.$emit('on-focus');
                    },
                    "contextmenu:row": (e, item)=>{
                        e.preventDefault();
                        item.select();
                        this.$emit("contextmenu", e);
                    },
                    "item-selected": this._selTbItem
                }
        });
    }   //render
};
</script>    
<style lang="scss">
    $selected: #aec7e3;
    $border-color: #d5cdb5;
    
    .v-data-table.jet-table {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > .v-data-table__wrapper {
            height: 100%;
            overflow-x: auto;
            overflow-y: auto;
            & > table {
                border-collapse: collapse;
                font-size: 0.8rem;
                & > tbody > tr {
                    & > td {
                        padding: 4px 6px;
                        height: auto;
                        border-left-color: transparent;
                        border-top-color: transparent;
                        border-bottom: 1px dotted $border-color !important;
                        border-right: 1px dotted $border-color !important;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 20rem;
                        font-size: 0.8rem;
                        &:first-child {
                            border-left: 8px solid transparent;
                            width: 2px;
                        }
                    }
                    &:hover{
                        & > td{
                            background-color: #efefef; /*#ffe284;*/
                            &:first-child {
                                border-left-color: #90a4ae;
                            }
                        }
                    }
                }
                & > thead > tr {
                    & > th {
                        position: relative;
                        background-color: #efefef !important;
                        padding: 4px 6px;
                        height: auto;
                        border-left-color: transparent;
                        border-top: 1px solid #fefefe;
                        border-bottom: 1px dotted $border-color !important;
                        border-right: 1px dotted $border-color !important;
                        text-align: left !important;
                        vertical-align: top;
                        font-weight: 400;
                        color: #0a0a0a;
                        &.named{font-weight: 600;}
                        &.sortable.active{
                            padding-right: 2.1rem;
                        }
                        & .jet-title{
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        & .v-data-table-header__icon{
                            font-size: 20px !important;
                            position: absolute;
                            top: 4px;
                            right: 1rem;
                            background: #efefef;
                            color: #666;
                            border-radius: 500px;
                        }
                        & .v-data-table-header__sort-badge{
                            position: absolute;
                            top: 5px;
                            right: 0rem;
                        }
                        & .jet-resizer{
                            position: absolute;
                            top: 0;
                            right: -1px;
                            width: 6px;
                            cursor: col-resize;
                            background-color: transparent;
                            user-select: none;
                            z-index: 999;
                            height: 100vh;
                            &:hover {
                                border-right: 2px dotted $border-color;
                            }
                        }
                        &.moving .jet-resizer{
                            border-right: 2px dotted darken($border-color, 30%);
                        }
                    }
                }
            }
        }
        & .v-data-footer{
            & .jet-name-item{
                margin: 0 0.5rem;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 50%;
            }
            & .v-btn--icon.v-size--default .v-icon{
                font-size: 0.7rem !important;
            }
        }
        & .v-simple-checkbox{
            & .v-icon.v-icon{
                font-size: .9rem;
            }
        }
        
    }   /*.v-data-table*/
    
    /* focused|selected */
    .theme--light.v-data-table tbody tr.v-data-table__selected {
        background: #efefef;
        & > td {
            border-bottom-color: darkblue !important;
            border-right-color:  darkblue !important;
        }
        & > td:first-child {
            border-left-color: darken($selected, 30%);
        }
    }
    .jet-focused {
        & .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
            background-color: #bcc8cf !important;
            & .v-data-table-header__icon{
                background-color: #bcc8cf !important;
            }
        }
        & .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover > td{
            background-color: #ffe284;
        }
        & .theme--light.v-data-table tbody tr.v-data-table__selected {
            background: $selected;
        }
    }
    
    
    .v-application--is-ltr .v-data-footer__select .v-select {
        margin: 3px 0 3px 14px;
    }    
    
</style>