<template>
  <div class="jet-input jet-input-num" v-show="vis">
        <v-text-field
          type="text"
          :value="value"
          :disabled="dis"
          :rules="rules"
          :hidden="hidden"
          :label="label"
          @input="on_input($event)">
            <template v-slot:append-outer>
                <jet-input-btn />
            </template>
        </v-text-field>
  </div>
</template>

<script>
import JetInputBase from '@/components/editors/JetInputBase';
import JetInputBtn from '@/components/editors/JetInputBtn';
import Inputmask from "inputmask";

export default {
  name: 'JetInputFloat',
  extends: JetInputBase,
  data() {
    return {
      prevValue: this.$attrs.value
    };
  },
  components: {
      JetInputBtn
  },
  computed: {
    value() {
      return this.$attrs.value;
    }
  },
  created(){
      this.$nextTick(()=>{
          const node = $(this.$el).find('input');
          Inputmask({"mask": "99,999,999.99"}).mask(node.get(0));
      });
  },
  methods: {
    on_input(e) {
        console.log(e);
        this.$emit('input', e);
    }
  }
};
</script>
<style lang="scss" scoped>
    .jet-input-num{
        max-width: 10rem;
    }
</style>