import Vue from 'vue';

import moment from 'moment';
import jQuery from 'jquery'
import VueSplit from 'vue-split-panel';

import '@babel/polyfill';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fortawesome/fontawesome-free/css/all.css';

import './plugins/functions';
import vuetify from './plugins/vuetify';

import App from './App.vue';

import router from './router';

import store from './store';

// FontAwesome
import {library} from '@fortawesome/fontawesome-svg-core';
import {faPlayCircle, faPauseCircle, faStopCircle, faExclamationCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import {faProductHunt} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(
  faPlayCircle,
  faPauseCircle,
  faStopCircle,
  faProductHunt,
  faExclamationCircle,
  faMinusCircle
);
Vue.component('fa-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.use(VueSplit);
window.jQuery = jQuery;
window.$ = jQuery;

new Vue({
  created(){
    this.$store.dispatch('branding/fillBranding').then(() => {
      const s = this.$store.getters['branding/get']('brand.web.system.name');

      if (!$utils.isEmpty(s)) {
        document.title = s;
      }
    });

    this.$store.dispatch('profile/check');
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

export const ebus = new Vue();
