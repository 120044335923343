<template>
  <div :class="classes">
    <div class="jet-input__container">
      <label
        v-show="hasLabel"
        class="jet-input__label"
      >
        {{ label }}
      </label>
      <input
        v-if="!multiline"
        type="text"
        :value="value"
        :disabled="disabled"
        :hidden="hidden"
        :placeholder="placeholder"
        class="jet-input__control"
        @keydown="onKeydown($event)"
        @input="onInput($event)"
        @focus="onFocus($event)"
        @blur="onBlur($event)"
      >
      <textarea
        v-else
        rows="5"
        :value="value"
        :disabled="disabled"
        :hidden="hidden"
        :placeholder="placeholder"
        class="jet-input__control"
        @keydown="onKeydown($event)"
        @input="onInput($event)"
        @focus="onFocus($event)"
        @blur="onBlur($event)"
      />
    </div>
  </div>
</template>

<script>
import JetInputBase from '@/components/editors/JetInputBase';

export default {
  name: 'JetInputString',
  extends: JetInputBase,
  computed: {
    classes() {
      const {
        multiline: isMultiline,
        isFocused,
        hasValue,
        hasPlaceholder
      } = this;

      const classes = ['jet-input'];
      const typeClass = isMultiline
        ? 'jet-input_type_string-multiline'
        : 'jet-input_type_string';

      classes.push(typeClass);

      if (isFocused) classes.push('jet-input_is-focused');
      if (hasValue) classes.push('jet-input_has-value');
      if (hasPlaceholder) classes.push('jet-input_has-placeholder');

      return classes;
    },
    value() {
      return this.$attrs.value;
    }
  },
  methods: {
    onKeydown(e) {
      const {code, target} = e;
      const codes = ['ArrowRight', 'ArrowLeft', 'Backspace', 'Delete'];
      const isAllowedCode = codes.indexOf(code) !== -1;

      if (
        this.maxLength !== 0
        && !isAllowedCode
        && (target.value.length > this.maxLength - 1)
      ) {
        e.preventDefault();
      }
    },
    onInput(e) {
      this.$emit('input', e.target.value);
    }
  }
};
</script>
