// Vuex
import store from '@/store/index';

// Сервисы
import CarrierUtils from '@/components/dev/service/CarrierUtils';

// Утилиты
import {prepareSinRequest, prepareSinResponse, postPublicApi} from '@/utils/http';
import {isEmpty, formatDate} from '@/utils/utils';
import md5 from 'blueimp-md5';

// События ТС
const _lastEvents = CarrierUtils.lastEvents();

const NULL_ID = '00000000-0000-0000-0000-000000000000';

/**
 * Сервис для работы с ТС
 */
export default class VehicleService {
  /**
   * Получение актуальной информации по ТС
   *
   * @param {Array<String>|String} vehicleIds
   * @return {Array|Object}
   */
  static async getLastInfo(vehicleIds) {
    const isArray = Array.isArray(vehicleIds);
    const ids = isArray ? vehicleIds.join(',') : vehicleIds;

    try {
      const lastActive = await jet.http.post(`/publicApi?call=lastVehicle&arg.ids=${ids}`);

      return lastActive
        && lastActive.length > 0
        && (isArray ? (lastActive || []) : (lastActive[0] || {}));
    } catch (e) {
      console.error('VehicleService::getLastInfo', e);

      return isArray ? [] : {};
    }
  }

  /**
   * Получение списка ТС
   *
   * Пример объекта ТС
   * ```js
   * {
   *  acid: "b5f4623c-b831-4310-b5d5-ce97533d5b96",
   *  govnum: "а542кх123",
   *  id: "844fd540-4db7-4088-8215-9568dea824a2",
   *  lasttime: "2021-03-22T10:04:10Z",
   *  ll: "44.8297760000000025:41.3761640000000028",
   *  orgid: "9268c80b-3aa0-4ad0-88d0-a1adf5760036",
   *  orgname: "АО ПАТП №1 г.Армавир",
   *  origid: "868004026652035",
   *  routecode: "Не задан",
   *  routename: null,
   *  state: 0,
   *  tripcode: null,
   *  tripscount: 0,
   *  vckindname: "ЛАЗ",
   *  vctypename: "Автобус",
   * }
   *
   * @returns {Promise<Array<{
   *   acid: String,
   *   govnum: String,
   *   id: String,
   *   lasttime, String,
   *   ll: String,
   *   orgid: String,
   *   orgname: String,
   *   origid: String,
   *   routecode: String,
   *   routename: String,
   *   state: Number,
   *   tripcode: String,
   *   tripscount: Number,
   *   vckindname: String,
   *   vctypename: String
   * }>|*[]>}
   */
  static async getListNew() {
    try {
      return prepareSinResponse(
        await prepareSinRequest(
          'query',
          '4ff9688e-277b-48a0-b211-b1e874e6f5f9.cbVehicles',
          '',
          [],
          {
            tenantId: store.state.auth.subject.tenantId,
            vcId: NULL_ID,
          },
        ),
      );
    } catch (e) {
      console.error('VehicleService::getListNew', e);

      return [];
    }
  }

  /**
   * Получение трека
   *
   * @param {Array<Object>|Object} vehicles ТС
   * @param {Date} dateStart Дата начала
   * @param {Date} dateEnd Дата окончания
   * @return {Promise<Array<Object>>}
   */
  static async getHistoryTrack(vehicles, dateStart, dateEnd) {
      if ( !Array.isArray(vehicles) ){
          vehicles = [vehicles];
      }

      const ids = vehicles.map(it => it.id);
      let url = `/publicApi?call=history`;
      url += `&arg.ids=${ids.join(',')}`;
      url += `&arg.start=${dateStart.getTime()}`;
      url += `&arg.end=${dateEnd.getTime()}`;
      url += '&arg.group=1';

      return jet.http.get(url);
  }

  /**
   * Получение последних статусов
   *
   * @param {Array<String>} vehicleIds Набор идентификаторов ТС
   * @returns {Promise<{}|*>}
   */
  static async getStatuses(vehicleIds) {
    try {
      return await $.ajax({
        url: '/publicApi?call=status',
        method: 'POST',
        data: `arg.ids=${vehicleIds.join(',')}`,
      });

    } catch (e) {
      console.error('VehicleService::getStatuses', e);

      return {};
    }
  }

  /**
   * Получение списка ТС
   *
   * @return {Promise<[]|*[]>}
   */
  static async getList() {
    try {
      const NULL_ID = '00000000-0000-0000-0000-000000000000';
      const opts = {
        type: 'query',
        query: '4ff9688e-277b-48a0-b211-b1e874e6f5f9.cbVehicles',
        params: {
          tenantId: store.state.auth.subject.tenantId,
          vcId: NULL_ID,
        },
      };

      if (isEmpty(opts.params.tenantId)) {
        opts.params.tenantId = NULL_ID;
      }

      const data = await jet.http.post(opts);

      if (!!data.error) {
        throw data.error;
      }

      const rResult = data.result || {};
      const ci = rResult.fields;
      const result = [];

      const fi = {
        id: ci.indexOf('id'),
        oid: ci.indexOf('orgid'),
        oname: ci.indexOf('orgname'),
        gov: ci.indexOf('govnum'),
        t: ci.indexOf('vctypename'),
        k: ci.indexOf('vckindname'),
        state: ci.indexOf('state'),
        r_code: ci.indexOf('routecode'),
        r_name: ci.indexOf('routename'),
        last: ci.indexOf('lasttime'),
        acid: ci.indexOf('acid'),
      };

      data.result.data.forEach(it => {
        result.push({
          id: it[fi.id],
          oid: it[fi.oid],
          oname: it[fi.oname],
          gov: it[fi.gov].toUpperCase(),
          t: it[fi.t],
          k: it[fi.k],
          state: it[fi.state],
          r_code: it[fi.r_code],
          r_name: it[fi.r_name],
          last: it[fi.last],
          acid: it[fi.acid],
          dispAttrs: null
        });
      });
      return result;
    } catch (e) {
      console.error('ERR (VehicleSerice::getList)', e);
      jet.msg({text: 'Данные ТС не получены', color: 'warning'});
      return [];
    }
  } //getList

  static async getVehiclesDate(dateWork) {
    try {
      const query = '4ff9688e-277b-48a0-b211-b1e874e6f5f9.cbVehiclesDate';
      await store.dispatch('cache/removeItem', md5('query' + query));
      const vehicles = await prepareSinResponse(
        await prepareSinRequest(
          'query',
          query,
          '',
          [],
          {
            tenantId: store.state.auth.subject.tenantId,
            vcId: '00000000-0000-0000-0000-000000000000',
            wrkDate: dateWork,
          },
        ),
        {
          hideUnderscores: true,
          keyReplace: {
            orgname: 'oname',
            govnum: 'gov',
            tripscount: 'tripsCount',
            orgid: 'oid',
            vctypename: 't',
            vckindname: 'k',
            routecode: 'r_code',
            routename: 'r_name',
            lasttime: 'last',
          },
        },
      );
      vehicles.forEach(vh => vh.gov.toUpperCase());
      return vehicles;
    } catch (e) {
      console.log('VehicleService.getVehiclesDate()', e);
      return [];
    }
  }

  static getTrackMarkers(track, points) {
    let zeroPoint = null;
    const markers = [];

    for (let i = 0, len = points.length; i < len; i++) {
      const point = points[i];
      const coords = [
        point.lon,
        point.lat,
      ];

      // Если это уже не первый элемент
      if (i - 1 > -1) {
        const prevPoint = points[i - 1];

        const diff = point.time - prevPoint.time;
        const minutes = Math.abs((diff / (1000 * 60)) % 60);

        // Если разница между точками - 15 минут, то это "Потеря связи"
        if (minutes >= 15) {
          markers.push({
            type: _lastEvents.noData,
            coordinates: coords,
            pos: i,
          });
        }
      }

      // Если тревожная кнопка
      if (point.type === 'ALARM_BUTTON') {
        markers.push({
          type: _lastEvents.alarm,
          coordinates: coords,
          pos: i,
        });

        continue;
      }

      // Нашли момент, когда скорость нулевая, значит проверяем на остановку
      if (point.speed === 0) {
        zeroPoint = point;

        let jCounter = 0;

        // Ищем увеличение скорости
        for (let j = i; j < len; j++) {
          const _point = track[0].points[j];

          if (_point.speed > 0) {
            const diff = _point.time - zeroPoint.time;
            const minutes = Math.abs((diff / (1000 * 60)) % 60);

            if (minutes >= 1 || minutes <= 20) {
              markers.push({
                type: _lastEvents.stop,
                coordinates: [
                  zeroPoint.lon,
                  zeroPoint.lat,
                ],
                pos: j,
              });
            }

            if (minutes > 20) {
              markers.push({
                type: _lastEvents.parking,
                coordinates: [
                  zeroPoint.lon,
                  zeroPoint.lat,
                ],
                pos: j,
              });
            }

            zeroPoint = null;
            jCounter++;

            break;
          }

          jCounter++;
        }

        // Сдвигаемся на кол-во пропущенных элементов
        i += jCounter;

        continue;
      }

      // Нашли превышение
      if (point.speed > 0) {
        markers.push({
          pos: i,
          type: point.speed > 90 ? _lastEvents.speedUp : _lastEvents.moving,
          coordinates: coords,
          params: {
            heading: point.heading + 100,
          },
        });
      }
    }

    return markers;
  }

  /**
   * Получение перевозчиков
   *
   * @returns {Promise<{
   *    regionalPriority: *,
   *    endDate: *,
   *    orgInn: *,
   *    orgPlace: *,
   *    parentCarrier: *,
   *    factAddress: *,
   *    orgTlf: *,
   *    tenantTitle: *,
   *    orgId: *,
   *    orgEmail: *,
   *    tenantName: *,
   *    orgCode: *,
   *    tenantId: *,
   *    hdrFio: *,
   *    orgInfo: *,
   *    hdrId: *,
   *    id: *,
   *    shortName: *,
   *    startDate: *
   * }[]|*[]>}
   */
  static async getCarriers() {
    try {
      const data = await jet.http.post('/rpc?d=jsonRpc', {
        type: 'core-read',
        query: 'sin2:/v:ad8fd1d7-e1b8-4edc-926c-dfd45c2da6ae',
      });

      if (!!data && data.result) {
        const response = data.result;
        const rData = response.data || [];

        const ci = response.columnIndexes || {};

        return rData.map(it => {
          /*
          _ssc_crud_delete: 21
          _ssc_crud_detail: 19
          _ssc_crud_update: 20
          */
          return {
            id: it[ci['vcautocarrier.id']],
            orgId: it[ci['vcautocarrier.orgid']],
            orgEmail: it[ci['vcautocarrier.orgid.email']],
            startDate: it[ci['vcautocarrier.startdt']],
            endDate: it[ci['vcautocarrier.enddt']],
            tenantId: it[ci['vcautocarrier.tenantid']],
            tenantName: it[ci['vcautocarrier.tenantid.name']],
            tenantTitle: it[ci['vcautocarrier.tenantid.title']],
            factAddress: it[ci['vcautocarrier.orgid.factaddress']],
            hdrId: it[ci['vcautocarrier.orgid.hdrid']],
            hdrFio: it[ci['vcautocarrier.orgid.hdrid.fio']],
            orgCode: it[ci['vcautocarrier.orgid.orgcode']],
            orgInn: it[ci['vcautocarrier.orgid.orginn']],
            orgPlace: it[ci['vcautocarrier.orgid.orgplace']],
            orgTlf: it[ci['vcautocarrier.orgid.orgtlf']],
            shortName: it[ci['vcautocarrier.orgid.shortname']],
            orgInfo: it[ci['vcautocarrier.orginfo']],
            parentCarrier: it[ci['vcautocarrier.parentcarrier']],
            regionalPriority: it[ci['vcautocarrier.regionalpriority']],
          };
        });
      }
    } catch (e) {
      return [];
    }
  }

  static getVehiclesCarrier(carrierId, dateWork) {
    return jet.http.post('/rpc/?d=jsonRpc', {
      type: 'core-read',
      query: `sin2:/v:6f43dbc2-c832-48e9-8873-5ce69edd097c/?filter=and(or(eq(field(".crrid"),param("${carrierId}","id")),exists("883513b6-7c41-45e4-baa2-ab55bbf7d2cf","and(or(eq(field(\\".carrierid\\"),param(\\"${carrierId}\\",\\"id\\")),eq(field(\\".subcarrierid\\"),param(\\"${carrierId}\\",\\"id\\"))),or(eq(field(\\".carrierid\\"),super.field(\\".crrid\\")),eq(field(\\".subcarrierid\\"),super.field(\\".crrid\\"))))")),lte(field(".startdt"),param("${dateWork}","date")),or(isnull(field(".enddt")),gt(field(".enddt"),param("${dateWork}","date"))))`,
    });
  }

  /**
   * Возвращает массив пар ID ТС и Госномер ТС
   *
   * @param {String} carrierId
   * @param {String} dateWork
   * @return {Promise<[]>}
   */
  static async getIdAndGovnum(carrierId, dateWork) {
    const response = await VehicleService.getVehiclesCarrier(
      carrierId,
      dateWork,
    );
    let vehicles = [];
    if (!response.error) {
      vehicles = response.result.data;

      vehicles = vehicles.map(item => ({
        vehicleId: item[0],
        govnum: item[1].toUpperCase(),
      }));
    }
    return vehicles;
  };

  /**
   * Получение списка арендованных автомобилей
   *
   * @return {Promise<Array<Object>>}
   */
  static async getCarrierRent() {
    try {
      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          'sin2:/v:883513b6-7c41-45e4-baa2-ab55bbf7d2cf/?filter=or(isnull(field(\".enddt\")),gte(field(\".enddt\"),var(\"dateWork\")))',
        ),
      );
    } catch (e) {
      console.log('VehicleService::getCarrierRent', e);

      return [];
    }
  }
  
  static async getVcTrip(vehicleId, at) {
      var at = (!!at) ? (new Date()).getTime() : at;
      return postPublicApi(`/api/v1/routes/{$ vehicleId}/vehicles?dateWork=${ at }`);
  }

  static async getVcTrips(vehicle, period) {
      return new Promise(async (resolve, reject)=>{
          try {
              var res = prepareSinResponse(
                      await prepareSinRequest(
                            'core-read',
                            `sin2:/v:10f4859f-ca95-4508-b232-61694c7ca6a7?filter=and(
                                    eq(field(".vehicle"), param("${ vehicle.id }", "id")),
                                    and(
                                        gt(field(".endDt"), param("${formatDate(period.start, "YYYY-MM-DD HH:mm:ss")}", "date")),
                                        lte(field(".startDt"), param("${formatDate(period.end, "YYYY-MM-DD HH:mm:ss")}", "date"))
                                    )
                            )&fields=vctrips.starttrip,vctrips.endtrip,vctrips.schedule.tripcode,vctrips.route.routecode,vctrips.route.routename,vcdepartures.name`
                      )
              );
              resolve(res);
          } catch(e){
              console.log('ERR VehicleService::getVcTrips', e);
              reject(e);
          }
      });
  } //getVcTrips
  
    static async getBlockStatus(vehicles) {
    try {
      return prepareSinResponse(
        await prepareSinRequest(
          'query',
          '14efced2-8c59-4111-a8e7-79b514eed84b.getBlockStatus',
          '',
          [],
          {
            vehicles: `{${ vehicles }}`,
          },
        ),
      );
    } catch (e) {
      console.error('VehicleService::getBlockStatus', e);

      return [];
    }
  } //getBlockStatus
  
  static async getVehiclesForMap() {
    try {
      return prepareSinResponse(
        await prepareSinRequest(
          'query',
          '14efced2-8c59-4111-a8e7-79b514eed84b.getVehiclesForMap',
          '',
          [],
          {
            tenantID: store.state.auth.subject.tenantId
          },
        ),
      );
    } catch (e) {
      console.error('VehicleService::getVehiclesForMap', e);

      return [];
    }
  }
}