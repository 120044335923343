import { prepareSinRequest, prepareSinResponse } from '@/utils/http';

import { formatDate, FORMAT_FOR_SERVER } from '@/services/JetDate';

import store from '@/store';

/**
 * Сервис получения информации по маршрутам
 */
export default class PlansService {
  /**
   * Функция для получения id_перевозчика, имени, его маршрутов
   * и общих данных по планированию
   * @param tenantId
   * @param date
   * то получим данные на сегодня
   */
  static async getPlans(tenantId, date) {
    const routes = [];
    const request = {
      type: 'query',
      query: 'ec7e9f5f-6357-41a7-94c4-d42a17b2e2aa.cabman_planning_date',
      params: {
        tenantId,
        date: formatDate(new Date(date), FORMAT_FOR_SERVER.DATE_TIME),
      },
    };

    const response = await jet.http.post('/rpc/?d=jsonRpc', request);
    if (!response.error) {
      const data = response.result.data;
      for (const routeRaw of data) {
        const route = {
          orgId: routeRaw[0],
          carrierId: routeRaw[10],
          carrierName: routeRaw[1],
          routeName: routeRaw[2] + ' ' + routeRaw[3],
          departurePlan: routeRaw[4],
          departureTotal: routeRaw[5],
          tripPlan: routeRaw[6],
          tripTotal: routeRaw[7],
          percentComplete: Math.min(100, routeRaw[8]),
          routeId: routeRaw[9],
        };
        route.completed = route.tripPlan / route.tripTotal === 1;
        route.routePlan = route.tripPlan + '/' + route.tripTotal;

        routes.push(route);
      }
    }
    return routes;
  }

  static async loadRoutes() {
    return prepareSinResponse(
      await prepareSinRequest(
        'core-read',
        'sin2:/v:acd2f542-598c-4540-b46c-03375098d467',
      ),
    );
  }

  /**
   * Функция для добавления постановок
   * @param host
   * @param params {Array}
   */
  static addTrips(params) {
    return jet.http.post('/rpc/?d=jsonRpc', {
      type: 'core-create',
      query: 'sin2:/v:10f4859f-ca95-4508-b232-61694c7ca6a7/',
      params,
    });
  }

  /**
   * Функция планирования на период
   * @param host
   * @param params {Array}
   */
  static addTripsOnPeriod(params) {
    return jet.http.post('/rpc/?d=jsonRpc', {
      type: 'core-create',
      query: 'sin2:/v:13861412-ad12-4152-b7aa-d2517a22a613/',
      params,
    });
  }

  /**
   * Проверка наличия нарушения норм труда
   * @param params {Array}
   */
  static checkWorkNorms(params) {
    return jet.http.post('/rpc/?d=jsonRpc', {
      type: 'query',
      query: '2f642407-7a1b-4e22-9c78-83abe4eae077.ctlPlaning',
      params: params,
    });
  }

  /**
   * Функция для удаления постановок
   * @param host
   * @param tripId - id постановки
   * @param endDate равен startDate
   */
  static deleteTrips(tripId, endDate) {
    return jet.http.post('/rpc/?d=jsonRpc', {
      type: 'core-update',
      query: 'sin2:/v:10f4859f-ca95-4508-b232-61694c7ca6a7/',
      params: [
        {
          id: 'id',
          type: 'id',
          value: tripId,
        },
        {
          id: 'EndDt',
          type: 'date',
          value: endDate,
        },
      ],
    });
  }

  /** Функция для получения остановок */
  static async getStops(scheduleId, dateWork) {
    const response = await jet.http.post('/rpc/?d=jsonRpc', {
      type: 'core-read',
      // eslint-disable-next-line max-len
      query: `sin2:/v:672eecfd-4340-41f9-a62f-41a4f77ebc29/?filter=and(eq(field(".schedule"),param("${scheduleId}","id")),not(exists(("10f4859f-ca95-4508-b232-61694c7ca6a7"),"and(eq(super.field(\\".schedule\\"),field(\\".schedule\\")),lt(field(\\".startDt\\"),field(\\".endDt\\")),eq(field(\\".startDt\\"),param(\\"${dateWork}\\",\\"date\\")),or(eq(super.field(\\".id\\"),field(\\".lastStop\\")),eq(super.field(\\".id\\"),field(\\".firstStop\\"))))")),or(isnull(field(".isMissedStop")),eq(field(".isMissedStop"),param(false,"boolean"))))`,
    });
    const stops = [];
    if (!response.error) {
      const data = response.result.data;
      for (const row of data) {
        const stop = {
          stopId: row[8],
          name: row[2],
          stopNumber: row[0],
        };
        stops.push(stop);
      }
      ;
    }
    stops.sort((a, b) => {
      return a.stopNumber - b.stopNumber;
    });
    return stops;
  }

  /** Функция для копирования постановок с одной даты на другую */
  static async importTrips(srcDate, dstDate) {
    const response = await jet.http.post('/rpc/?d=jsonRpc', {
      type: 'query',
      query: 'view:6f43dbc2-c832-48e9-8873-5ce69edd097c.copyTrips',
      params: {
        userid: store.state.auth.subject.id,
        tenantid: store.state.auth.subject.tenantId,
        srcDate: srcDate,
        dstDate: dstDate,
      },
    });
    if (!response.error) {
      const data = response.result.data[0];
      const result = {
        planned: data[0],
        all: data[1],
      };
      return result;
    }
  }

  static getTripsRoute(dateWork, routeId) {
    return [
      // График
      jet.http.post('/rpc/?d=jsonRpc', {
        type: 'query',
        query: '97c57863-9ae2-4002-8de1-7cec4fb75638.getSchedules',
        params: {
          in_tenantID: store.state.auth.user.tenantId,
          in_routeID: routeId,
          in_date: dateWork,
        },
      }),
      // Постановки
      jet.http.post('/rpc/?d=jsonRpc', {
        type: 'core-read',
        // eslint-disable-next-line max-len
        query: `sin2:/v:10f4859f-ca95-4508-b232-61694c7ca6a7?filter=and(or(eq(field(".route.BASEROUTEID"),param("${routeId}","id")), eq(field(".route.ID"),param("${routeId}","id")) ),and(or(isnull(field(".endDt")),gt(field(".endDt"),param("${dateWork}", "date"))),lte(field(".startDt"),param("${dateWork}", "date"))))&fields=vctrips.id,vctrips.schedule,vctrips.vehicle,vcdepartures.name,vctrips.firststop.routepoint.location.locname,vctrips.firststop.routepoint.pointnumber,vctrips.startTrip,vctrips.endTrip`,
      }),
    ];
  }

  static getTripsVehicle(dateWork, vehicleId) {
    return [
      // Рейсы
      jet.http.post('/rpc/?d=jsonRpc', {
        type: 'query',
        query: '97c57863-9ae2-4002-8de1-7cec4fb75638.getSchedulesByVehicle',
        params: {
          in_tenantID: store.state.auth.user.tenantId,
          in_vehicleID: vehicleId,
          in_date: dateWork,
        },
      }),
      // Постановки
      jet.http.post('/rpc/?d=jsonRpc', {
        type: 'core-read',
        // eslint-disable-next-line max-len
        query: `sin2:/v:10f4859f-ca95-4508-b232-61694c7ca6a7?filter=and(eq(field(".vehicle"),param("${vehicleId}","id")),and(or(isnull(field(".endDt")),gt(field(".endDt"),param("${dateWork}", "date"))),lte(field(".startDt"),param("${dateWork}", "date"))))&fields=vctrips.id,vctrips.schedule,vctrips.vehicle,vcdepartures.name,vctrips.firststop.routepoint.location.locname,vctrips.firststop.routepoint.pointnumber,vctrips.startTrip,vctrips.endTrip`,
      }),
    ];
  }

  /**
   * Функция для получения рейсов + постановок маршрута
   * @param dateWork {Date} в формате formatDateTime (переменная)
   * @param routeId {String} - id маршрута
   * @param vehicles {Array} - список ТС (пара: id TC и Госномер)
   */
  static async getTrips(dateWork, routeId, vehicles, vehicleId) {

    function getGovnum(vehicleId) {
      const row = vehicles.find(item => {
        return item.vehicleId === vehicleId;
      });
      return row?.govnum ?? 'Неизвестно';
    }

    function formatTimezone(date) {
      date = new Date(date);
      date.setTime(date.getTime() + 30 * 60 * 1000);
      return date;
    }

    const result = [];
    await Promise.all(
      !!routeId ? this.getTripsRoute(dateWork, routeId) : this.getTripsVehicle(dateWork, vehicleId),
    ).then(res => {
      if (!res[0].error || !res[1].error) {
        // Выбираем необходимые поля в рейсах (schedules)
        const schedules = prepareSinResponse(
          res[0],
          {
            keyReplace: {
              id: 'scheduleId',
              depname: 'departureName',
              tripcode: 'tripCode',
              depid: 'departureId',
              firststopid: 'firststop',
              laststopid: 'laststop',
              starttm: 'startTime',
              endtm: 'endTime',
              startdt: 'startDt',
            },
          },
        );
        schedules.forEach(sch => {
          sch.startTime = formatTimezone(sch.startTime);
          sch.endTime = formatTimezone(sch.endTime);
          sch.selected = false;
          sch.planned = false;
          sch.trips = [];
        });

        // Выбираем необходимы поля в постановках (trips)
        const ci = res[1].result.columnIndexes;

        const trips = res[1].result.data.map(trip => {
          return {
            tripId: trip[ci['vctrips.id']],
            scheduleId: trip[ci['vctrips.schedule']],
            vehicleId: trip[ci['vctrips.vehicle']],
            departureName: trip[ci['vcdepartures.name']],
            locationName: trip[ci['vctrips.firststop.routepoint.location.locname']],
            stopNumber: trip[ci['vctrips.firststop.routepoint.pointnumber']],
            startTrip: trip[ci['vctrips.starttrip']],
            endTrip: trip[ci['vctrips.endtrip']],
          };
        });

        // Связываем постановки с графиками
        // + Добавляем номера ТС
        const related = [];
        for (const schedule of schedules) {
          const tripsSchedule = trips.filter(item => {
            return item.scheduleId === schedule.scheduleId;
          });
          if (tripsSchedule.length > 0) {
            for (const trip of tripsSchedule) {
              trip.govnum = getGovnum(trip.vehicleId);
            }
            schedule.trips = tripsSchedule;
            schedule.planned = true;
          }
          related.push(schedule);
        }
        let number = 0;
        // Собираем рейсы в выезды, если возможно
        for (const relate of related) {
          let departureId;
          if (relate.departureId) {
            departureId = relate.departureId;
          }
          let index = -1;
          if (departureId) {
            index = result.findIndex(item => {
              return item.id === departureId;
            });
          }
          if (index !== -1) {
            result[index].schedules.push(relate);
            result[index].total++;
            if (relate.planned) {
              result[index].planned = true;
              result[index].plan++;
            }
          } else {
            const departure = {
              id: departureId || number++,
              isDeparture: false,
              schedules: [relate],
              selected: false,
              planned: relate.planned,
              plan: relate.planned ? 1 : 0,
              total: 1,
            };
            if (departureId) {
              departure.title = relate.departureName;
              departure.isDeparture = true;
            }
            result.push(departure);
          }
        }
      }
    });
    // Сортируем остановки
    for (const departure of result) {
      for (const schedule of departure.schedules) {
        schedule.trips.sort(function compareStops(a, b) {
          if (a.stopNumber < b.stopNumber || !a.locationName) {
            return -1;
          }
          if (a.stopNumber > b.stopNumber || !b.locationName) {
            return 1;
          }
          return 0;
        });
      }
    }
    if (result.length === 0 || result[0].schedules.length === 0) {
      return [{ schedules: [] }];
    }
    return result;
  }

  /**
   * Функция для проверки пересечения постановок
   * @param vehicleId {String} - id ТС для которого производится планирование
   * @param tripStart {String} - Начало постановки
   * @param tripEnd {String} - Конец постановки
   */
  static async intersectionTrips(vehicleId, tripStart, tripEnd) {
    const intersections = await prepareSinResponse(
      await prepareSinRequest(
        'core-read',
        `sin2:/v:10f4859f-ca95-4508-b232-61694c7ca6a7?filter=and(eq(field(".vehicle"),param("${vehicleId}","id")),gt(field(".endDt"), param("${tripStart}","date")),gt(field(".endTrip"), param("${tripStart}","date")),lt(field(".startTrip"), param("${tripEnd}","date")))`,
        '',
        [],
        null,
        {
          cache: false,
        },
      ),
      {
        hideUnderscores: true,
        keyReplace: {
          'route.routecode': 'routeCode',
          'route.routename': 'routeName',
          'schedule.tripcode': 'tripCode',
          'startdt': 'startDt',
        },
      },
    );
    intersections.forEach(tr => {
      tr.routeName = tr.routeCode + ' ' + tr.routeName;
      tr.tripId = tr.id;
    });
    return intersections;
  }
}
