<script>
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import JetSvg from '@/components/JetSvg';
import JetInputId from '@/components/editors/JetInputId';
import {watch} from '@/utils/dom';
import Model from '@/core/Model';

import { NULL_ID } from "@/utils";

const SCH_MODE = {
    'none': -1,
    'default': 0,
    'loading': 9,
    'error': 10
};

const $moment = require('moment');
const tz_shift_minutes = $moment(0).hours() * 60;
const now = $moment();


const _to_time = (dt)=>{
    const _t = $moment(dt).utc();       //reset timezone
    _t.set('date', now.get('date'));
    _t.set('month', now.get('month'));
    _t.set('year', now.get('year'));
    return _t.local();
};

const SchTimeDialog = {
    props: ['show', 'stop'],
    data(){
        return {
            _stop: null,
            hInput: false
        };
    },
    methods: {
        getAsTime(val){
            var d = new Date(now.toDate().getTime());
            if (!!val){
                if ((val.length > 2) && (val.indexOf(':') < 0)){
                    val = val.substr(0, 2) + ':' + val.substr(2);
                }
                var a = val.split(':');
                switch (a.length){
                    case 1:
                        d.setHours(a[0]);
                        break;
                    case 2:
                        d.setHours(a[0]);
                        d.setMinutes(a[1]);
                        break;
                }
            }
            return d;
        },
        tm(val, arr){
            if (!!this.hInput){
                clearTimeout(this.hInput);
            }
            this.hInput = setTimeout(()=>{
                this._stop[arr] = this.getAsTime(val);
                this.hInput = false;
            }, 500);
        },
        save(){

        }
    },
    watch: {
        show(val){
            this._stop = {};
        }
    },
    computed: {
        arr: {
            get(){
                var d = null;
                if ((!!this._stop)&&(!!this._stop.arr)){
                    d = this._stop.arr;
                }
                if (!!this.stop){
                    d = this.stop.arr;
                }
                return (!!d) ? $moment(d).format('HH:mm') : '';
            },
            set(val){
                this.tm(val, 'arr');
            }
        },
        dep: {
            get(){
                var d = null;
                if ((!!this._stop)&&(!!this._stop.dep)){
                    d = this._stop.dep;
                }
                if (!!this.stop){
                    d = this.stop.dep;
                }
                return (!!d) ? $moment(d).format('HH:mm') : '';
            },
            set(val){
                this.tm(val, 'dep');
            }
        }
    },
    render(h){
        return h('v-dialog', {props: {
            width: "500px",
            value: this.show
        }}, [
            h('v-card', {class: {"sch-stop-times": true}}, [
                h('v-card-title', 'Время движения'),
                h('v-card-text', [
                    h('h3', (!!this.stop) ? this.stop.pt : ''),
                    h('v-row', [
                        h('v-col', {props: {col: 6}}, [
                            h('v-text-field', {
                                props: {label: 'прибытие', type: 'time', value: this.arr},
                                on: {input: (val)=>{this.arr = val;}}
                            })
                        ]),
                        h('v-col', {props: {col: 6}}, [
                            h('v-text-field', {
                                props: {label: 'отправление', type: 'time', value: this.dep},
                                on: {input: (val)=>{this.dep = val;}}
                            })
                        ])
                    ])
                ]),
                h('v-card-actions', [
                    h('v-spacer'),
                    h('v-btn', {props: {outlined:true, tile:true, color: 'gray', small: true},
                                on: {click: ()=>{ this.$emit('close');}
                                }
                            }, 'закрыть'),
                    h('v-btn', {props: {outlined:true, tile:true, color: 'primary', small: true},
                                on: {click: this.save}
                               }, 'сохранить')
                ])
            ])
        ]);
    }

};  //SchTimeDialog

const SchFixDialog = {
    name: 'SchFixDialog',
    props: ['show', 'route', 'verId'],
    data(){
        return {
            mode: SCH_MODE.none,
            verInfo: null,
            fixDt: null,
            fixMenu: false
        };
    },
    computed: {
        canFix(){
            if (!!this.verInfo){
                if (!!this.verInfo.error){
                    return false;
                } else {
                    return /^проект/i.test(this.verInfo.name);
                }
            } else {
                return false;
            }
        }
    },
    watch: {
        show(val){
            if (!!val){
                this.fixDt = new Date();
                this.read();
            }
        }
    },
    methods: {
        async read(){
            this.mode = SCH_MODE.loading;
            const opts = {
                type: 'core-read',
                query: 'sin2:/v:17743043-925a-48e4-90b5-8f1e77226a01?filter=eq(field(".id"),param("' + this.verId + '","id"))'
            };
            try {
                var r = await $http.post(opts);
                if (r.error){
                    throw r.error;
                }
                if (r.result.data.length > 0){
                    this.verInfo = $utils.sin2obj(r.result.columnIndexes, r.result.data[0]);
                    this.mode = SCH_MODE.default;
                } else {
                    this.verInfo = {error: 'Данные не получены'};
                    this.mode = SCH_MODE.error;
                }
                //console.log('Version info:', this.verInfo);
            }catch(e){
                this.verInfo = {error: e};
                this.mode = SCH_MODE.error;
            }
        },  //read
        fix(){

        }   //fix
    },
    render(h){
        const m = $moment;
        return h('v-dialog', {props: {
            width: "640px",
            value: this.show
        }}, [
            h('v-card', {class: {"sch-fix": true}}, [
                h('v-card-title', 'Утвердить расписание'),
                h('v-card-text', [
                    (this.mode === SCH_MODE.loading)
                        ? h('v-skeleton-loader', {props:{type:'card-heading, image, actions'}})
                        : (this.mode === SCH_MODE.error)
                            ? h('v-alert', {props: {border:"top","colored-border": true,type:"warning"}}, 'Ошибка получения информации о версии маршрута')
                            : (this.mode === SCH_MODE.none)
                                ? null
                                : h('div', [
                                    h('h3', this.verInfo.routecode + '. ' + this.verInfo.routename),
                                    h('div', {class: {'mt-3': true, 'mb-3': true}},
                                              'версия № ' + this.verInfo.version +
                                              ' от ' + m(this.verInfo.verstart).format('DD.MM.YYYY') +
                                              ': ' + this.verInfo.name),
                                    h('v-menu', {
                                                    props: {"max-width": "290px", "min-width":"290px", value: this.fixMenu},
                                                    scopedSlots: {
                                                        activator: ({on, attrs}) => {
                                                            on["blur"] = (e)=>{
                                                                console.log("blur:", e);
                                                            };
                                                            return h('v-text-field', {
                                                                props: {
                                                                    label:"Дата утверждения",
                                                                    "append-icon": "mdi-calendar",
                                                                    value: m(this.fixDt).format('DD.MM.YYYY'),
                                                                    "full-width": false
                                                                },
                                                                style: {"max-width": "12rem"},
                                                                on: on
                                                            });
                                                        }
                                                    }
                                                }, [
                                                    h('v-date-picker', {props: {
                                                        value: this.fixDt.toISOString().substr(0, 10),
                                                        "no-title": true
                                                    }, on:{
                                                        input: (dt)=>{
                                                            //console.log(dt);
                                                            if (!dt){return;}
                                                            const [y, m, d] = dt.split('-');
                                                            this.fixDt = new Date(y, m-1, d);
                                                            this.fixMenu = false;
                                                        }
                                                    }})
                                                ]),
                                        this.canFix
                                            ? null
                                            : h('v-alert', {props: {outlined: true, type:"warning"}}, 'выбранная версия расписания не может быть утверждена')
                                    ])
                ]),
                h('v-card-actions', [
                    h('v-spacer'),
                    h('v-btn', {props: {outlined:true, tile:true, color: 'gray', small: true},
                                on: {click: ()=>{ this.$emit('close');}
                                }
                            }, 'закрыть'),
                    h('v-btn', {props: {outlined:true, tile:true, color: 'primary', small: true, disabled: !this.canFix},
                                on: {click: this.fix}
                               }, 'утвердить')
                ])
            ])
        ]);
    }
};  //SchFixDialog

const SchVerDialog = {
    name: 'SchVerDialog',
    props: ['show', 'route'],
    data(){
        return {
        };
    },
    render(h){
        return h('v-dialog',{props: {
            width: "600px",
            value: this.show
        }}, [
            h('v-card',[
                h('v-card-title', 'Новая версия расписания'),
                h('v-card-text', [
                    (!!this.route)
                        ? h('h3', this.route.vcroutesRoutecode + '. ' + this.route.vcroutesRoutename)
                        : null,
                    h('v-form', [

                    ])
                ]),
                h('v-card-actions', [
                    h('v-spacer'),
                    h('v-btn', {
                        props: {outlined:true, tile:true, color: 'gray', small: true},
                        on: {click: ()=>{this.$emit('close');}}
                    }, 'закрыть'),
                    h('v-btn', {props: {outlined:true, tile:true, color: 'primary', small: true}}, 'сохранить')
                ])
            ])
        ]);
    }
};

const SchRoutes = {
    name: 'SchRoutes',
    data(){
        return { comp: null };
    },
    components: {
        JetInputId,
        JetSvg
    },
    methods: {
        async getComp(){
            const self = this;
            return new Promise((resolve, reject)=>{
                var n = 0;
                const _wait = function(){
                    if ( n > 100 ){
                        reject();
                    } else if (!!self.comp){
                        resolve(self.comp);
                    } else {
                        n++;
                        setTimeout(_wait, 100);
                    }
                };
                _wait();
            });
        },  /* getComp */
        needRoute(id){
            this.getComp().then((comp)=>{
                var inp = comp.$refs["routeInp"];
                inp.setFilter('eq(field(\".id\"),param(\"' + id + '\",\"id\"))');
            });
        }
    },
    mounted(){
        const self = this;
        watch('.v-window-item--active .v-toolbar__title.jet-col-title').then((node)=>{
            $('.v-window-item--active .jet-col-search').css({display: "none"});
            self.comp = new Vue({
                name: 'MtRoutesChooser',
                el: node.get(0),
                vuetify,
                data(){
                    return {
                        routeId: null,
                        schVerId: null
                    };
                },
                components: { JetInputId },
                methods: {
                    verUri(){
                        const id = $utils.isEmpty(this.routeId) ? NULL_ID : this.routeId;
                        return `sin2:/v:17743043-925a-48e4-90b5-8f1e77226a01?filter=
                                    exists(\"17743043-925a-48e4-90b5-8f1e77226a01\",
                                                   \"and(
                                                       eq(field(\\\"s.verid\\\"),super.field(\\\"s.verid\\\")),
                                                       eq(field(\\\"s.id\\\"),param(\\\"${ id }\\\",\\\"id\\\"))
                                                   )\"
                                    )
                                    &sort=vcReleaseSchedules.verStatus.Name,-vcReleaseSchedules.verStart`;
                    }
                },
                provide: {
                    owner: {    //stub's
                                reg(comp){},
                                unreg(comp){},
                                val(name, val){}
                           }
                },
                render(h){
                    const routesUri = `sin2:/v:d122d351-3e3e-4983-a73c-199f0fa1bed5?sort=vcRoutes.routeCode&filter=
                                      and(
                                        exists(\"24b28908-8b07-4840-b9a9-995593478876\",
                                          \"and(
                                            eq(field(\\\".verId\\\"),super.field(\\\".verId\\\")),
                                            or(
                                              eq(field(\\\".verStatus.Name\\\"),param(\\\"Утвержден\\\", \\\"string\\\")),
                                              eq(field(\\\".verStatus.Name\\\"),param(\\\"Проект\\\", \\\"string\\\"))
                                            )
                                          )\"
                                        ),
                                        eq(field(\".isActiveRoute\"), param(true,\"boolean\"))
                                      )`;
                    return h('div', {
                        class: {'sch-routs-place': true}
                    }, [
                        h('jet-input-id', {props: {
                                label: 'маршрут',
                                type: 'id',
                                uri: routesUri,
                                noactions: true,
                                "hide-details": true,
                                required: true
                            },
                            attrs: { 
                                name: "route",
                                value: this.routeId
                            }, 
                            ref: "routeInp",
                            on: {
                                input: (val)=>{
                                    const rt = this.$refs["routeInp"],
                                          sh = this.$refs["schVer"];
                                    console.log('route input - ', val, rt.selected);
                                    this.routeId = (!!rt.selected) ? rt.selected.vcroutesId : null;
                                    this.schVerId = null;
                                    (async ()=>{
                                      await sh.refresh();
                                      const shItems = sh.getItems();
                                      var n = -1;
                                      var res = {
                                        version: -1,    n1: -1,
                                        verStart: -1,   n2: -1
                                      };
                                      //Выбор версии расписания для подстановки
                                      for (var i = 0; i < shItems.length; i++) {
                                        var item = shItems[i];
                                        if (item.vcreleaseschedulesVerstatusName === "Утвержден") {
                                          n = i;
                                          break;
                                        } else if (item.vcreleaseschedulesVerstatusName === "Проект" 
                                                && res.version < item.vcreleaseschedulesVersion) {
                                          res.version = item.vcreleaseschedulesVersion;
                                          res.n1 = i;
                                        } else if (item.vcreleaseschedulesVerstatusName === "Утратил силу" 
                                                && res.verStart < (new Date(item.vcreleaseschedulesVerstart)).getTime()) {
                                          res.verStart = (new Date(item.vcreleaseschedulesVerstart)).getTime();
                                          res.n2 = i;
                                        }
                                      };
                                      if (n === -1) {
                                        if (res.version > -1) {
                                          n = res.n1;
                                        } else if (res.verStart > -1) {
                                          n = res.n2; 
                                        }
                                      }
                                      if (n > -1) {
                                        sh.rowSelect(shItems[n]);
                                      }
                                    })();
                                    self.$emit("route", rt.selected);
                            }}
                        }),
                        h('jet-input-id', {props: {
                                label: 'версия расписания',
                                type: 'id',
                                uri: this.verUri(),
                                noactions: true,
                                "hide-details": true,
                                required: true
                            }, 
                            attrs: {
                                name: "schVer",
                                value: this.schVerId
                            },
                            ref: "schVer",
                            on: {
                                    input: (val)=>{ self.$emit('schedule', val); }
                                }
                        })
                    ]);
                }   //render
            });
        });
    },
    render(h){
        return h('i');
    }
};  //SchRoutes


export default {
    name: 'MtSchedules',
    data(){
        return {
            mode: SCH_MODE.none,
            route: null,
            stop: null,
            schVerId: null,
            deps: null,
            scheds: null,
            activeDep: null,
            showVer: false,
            showTime: false,
            fixSche: false
        };
    },
    components: {
        SchRoutes,
        SchFixDialog,
        JetSvg
    },
    created(){
        const model = new Model({
                                id: '3100ec29-e929-4a9c-83cc-15b87fbe2800',
                                columns: [],
                                projects: [{
                                        projectType: "typeJsClientHtml",
                                        title: "Новая версия расписания...",
                                        name: "verAdd",
                                        call: ()=>{this.showVer = (new Date()).getTime();}

                                }, {
                                        projectType: "typeJsClientHtml",
                                        title: "Утвердить версию расписания",
                                        name: "verFix",
                                        call: ()=>{this.fixSche =  (new Date()).getTime();}

                                }, {
                                        projectType: "typeJsClientHtml",
                                        title: "Копировать расписание",
                                        name: "verCpy",
                                        call: this.refresh

                                }, {
                                        projectType: "typeJsClientHtml",
                                        title: "Прекратить действие",
                                        name: "verEnd",
                                        call: this.refresh

                                }, {
                                        projectType: "typeJsClientHtml",
                                        title: "Добавить график...",
                                        name: "schAdd",
                                        call: this.refresh
                                }, {
                                        projectType: "typeJsClientHtml",
                                        title: "Добавить выезд...",
                                        name: "depAdd",
                                        call: this.refresh
                                }]
                        });
        this.$emit('on-model', model);
    },
    computed: {
        activeScheds(){
            if (!(!!this.scheds)){
                return [];
            }
            if (this.has("deps")){
                if (!!this.activeDep){
                    return this.scheds.filter((sch)=>{
                        return sch.depId === this.activeDep.id;
                    }).sort((s1, s2)=>{
                        return (s1.start < s2.end) ? -1 : 1;
                    });
                }
                return [];
            } else {
                return this.scheds.sort((s1, s2)=>{
                        return (s1.start < s2.end) ? -1 : 1;
                });
            }
        }   //activeScheds
    },
    methods: {
        has(q){
            switch(q){
                case "deps":
                    return this.deps?.length > 0;
                default:
                    return false;
            }
        },  //has
        set(name, val){
            switch(name){
                case "route":
                    this.setRouteId(val);
            }
        },
        setRouteId(id){
            const set = this.$refs["setup"];  //set on toolbar
            if (set){
                set.needRoute(id);
            }
        },
        async refresh(){
            if (this.mode === SCH_MODE.loading){
                return;
            }
            
            this.schInf = null;
            this.activeDep = null;
            this.mode = SCH_MODE.loading;
            const m = $moment;

            const deps = [],
                scheds = [];

            const exist = function(id){
                return (deps.filter((dep)=>{return (dep.id === id);}).length > 0);
            };

            try {
                const opts = {
                    type: 'core-read',
                    query: 'sin2:/v:11c7dbd5-d5fb-4cdf-964e-80cacd0c460a?filter=eq(field(".versionId"),param("'
                            + ($utils.isEmpty(this.schVerId) ? NULL_ID : this.schVerId) + '","id"))&sort=ordernumber'
                };
                var _r = await $http.post(opts);
                if (_r.error){
                    throw _r.error;
                }
                const ci = _r.result.columnIndexes;
                _r.result.data.map((item, n)=>{
                    var s = {
                        id: item[ci["vcschedules.id"]],
                        depId: item[ci["vcschedules.depid"]],
                        start: _to_time(item[ci["vcschedules.starttm"]]),
                        end:  _to_time(item[ci["vcschedules.endtm"]]),
                        code: item[ci["vcschedules.tripcode"]],
                        season: item[ci["vcschedules.season.name"]],
                        wkmode: item[ci["vcschedules.wkmode.modname"]],
                        stops: false,
                        tt: 0,
                        //calc when stop's loaded
                        need15: false,
                        need45: false,
                        has15: false,
                        has45: false
                    };
                    s.tt = m(s.end).diff(s.start, 'minutes');
                    s.icode = Number(s.code.replace(/\D/g, ''));
                    scheds.push(s);
                    
                    if ( !!s.depId && !exist(s.depId) ){
                        var d = {
                            id: s.depId,
                            name: item[ci["vcschedules.depid.name"]],
                            season: s.season,
                            wkmode: s.wkmode,
                            start: s.start,
                            end:  s.end
                        };
                        d.iname = Number(d.name.replace(/\D/g, ''));
                        deps.push(d);
                    }
                });

                deps.map((dep)=>{
                    dep.n = 0;
                    dep.tt = 0;
                    scheds.filter((sch)=>{return (sch.depId === dep.id);}).map((sch)=>{
                        dep.n++;
                        if (sch.start < dep.start){
                            dep.start = sch.start;
                        }
                        if (sch.end > dep.end){
                            dep.end = sch.end;
                        }
                    });
                    dep.tt = m(dep.end).diff(dep.start, 'minutes');
                });

                this.deps = deps.sort((d1, d2)=>{
                    return (d1.iname < d2.iname) 
                                ? -1
                                : (d1.iname > d2.iname) ? 1 
                                    : 0;
                });
                this.scheds = scheds;
                this.mode = SCH_MODE.default;
                console.log('SHED`s:', scheds);
            } catch(e){
                console.log('ERR (load)', e);
                jet.msg({type:'warning', text:'Не удается получить список выездов/рейсов по выбранному маршруту'});
                this.mode = SCH_MODE.error;
                //console.log(e);
            }
        },
        async stops(sch){
            if ((!!sch.stops)&&Array.isArray(sch.stops)){
                return;
            }
            if (sch.stops === SCH_MODE.loading){
                return;
            }
            sch.stops = SCH_MODE.loading;
            const m = $utils.moment;
            const prevs = this.scheds.filter((_sch)=>{
                return (sch.depId === _sch.depId) && (sch.start > _sch.end);
            }).sort((s1, s2)=>{
                return (s1.start < s2.end) ? -1 : 1;
            });
            const prev = (prevs.length > 0) ? prevs[prevs.length - 1] : null;

            try{
                const opts = {
                    type: 'core-read',
                    query: 'sin2:/v:bc9b51c1-601e-45a9-b485-2ef8392ec440?filter=eq(field(".schedule"),param("'
                            + sch.id + '","id"))&sort=vcschedulestops.routepointnum'
                };
                var _r = await $http.post(opts);
                if (_r.error){
                    throw _r.error;
                }
                const ci = _r.result.columnIndexes;
                var stops = [];
                _r.result.data.map((item, n)=>{
                    var s = {
                        id: item[ci["vcschedulestops.id"]],
                        arr: _to_time(item[ci["vcschedulestops.arrtime"]]),
                        dep: _to_time(item[ci["vcschedulestops.deptime"]]),
                        pt: item[ci["vcschedulestops.routepoint.location.locname"]],
                        rq: item[ci["vcschedulestops.isrequeststop"]],
                        miss: (!!item[ci["vcschedulestops.ismissedstop"]]),
                        stop: 0
                    };
                    //stop time (minutes)
                    var at = null;
                    if (n===0){ //first stop by prev schedule
                        at = (!!prev) ? prev.end : s.dep;
                    } else {
                        at = s.dep;
                    }
                    s.stop = (!!at) ? Math.abs(m(at).diff(s.arr, 'minutes')) : 0;
                    stops.push(s);
                });
                sch.stops = stops;
                this.chkRest(sch);
            }catch(e){
                console.log(e);
                sch.stops = false;
            }
        },   //stops
        set_time(stop){
            //console.log(stop);
            this.stop = stop;
            this.showTime = (new Date()).getTime();
        },
        chkRest(sch){
            if (!(!!this.scheds)){
                return;
            }
            var scheds = this.scheds.filter((_sch)=>{
                return (sch.depId === _sch.depId) && (sch.start > _sch.end);
            }).sort((s1, s2)=>{
                return (s1.start < s2.end) ? 0 : 1;
            });
            if (scheds.length < 1){
                return;
            }
            const m = $utils.moment;
            var reCalc = false,
                start15 = scheds[0].start,
                start45 = scheds[0].start;
            //1. check a need's
            scheds.map((sh, n)=>{
                if ( (reCalc) || !(!!sh.stops) || !Array.isArray(sh.stops) ){
                    reCalc = true;  //wait all stop's loading...
                    return;
                } else {
                    sh.stops.map((st, n)=>{
                        var d = m(st.dep).diff(start15, 'minutes');
                        if (d > 4*60) {
                            start15 = st.dep;
                            sh.need15 = true;
                        }
                        d = m(st.dep).diff(start45, 'minutes');
                        if (d > 5*60) {
                            start45 = st.dep;
                            sh.need45 = true;
                        }
                    });
                }
            });
            if (reCalc){
                setTimeout(()=>{this.chkRest(sch);}, 300);  //TODO: the bag
            }

        }   //chkRest
    },
    render(h){
        const m = $moment;
        var s;
        return h('v-container', {
                    props: {fluid: true},
                    class: {'fill-height': true, 'mt-shedules': true}
                }, [
            h('sch-routes', {
                ref: "setup",
                on: {
                        route: (r)=>{
                            this.route = r;
                        },
                        schedule: (schVerId)=>{
                            console.log('on schedule changed:', schVerId);  
                            this.schVerId = schVerId;                             
                            this.refresh();
                        }
                }
            }),
            (this.mode === SCH_MODE.default)
                ? h('v-row', [
                    h('v-col', {
                        props: {cols: 3}, 
                        class: {'sch-deps': true, 'd-none': !this.has("deps")}}, [
                        /* ============ Dep's for active route ============ */
                        h('v-timeline', {props: {dense: true}}, [
                           this.deps.map((dep)=>{
                                s = (dep.tt > 60)
                                    ? Math.trunc(dep.tt/60) + ' ч. ' + (dep.tt - (Math.trunc(dep.tt/60 )*60)) + ' мин.'
                                    : dep.tt + ' мин.';
                                return h('v-timeline-item', {
                                       key:'dep-' + dep.id,
                                       props: {small: true}
                                   }, [
                                       h('v-card', {
                                           class: {'sch-dep': true, 'sch-dep__active': ((!!this.activeDep)&&(this.activeDep.id === dep.id))},
                                           on: {click: ()=>{this.activeDep = dep;},
                                                contextmenu: ()=>{console.log('contextmenu:', dep);}
                                               }
                                       }, [
                                           h('v-card-text', [
                                               h('div', {class: {'d-flex': true, 'justify-space-between': true}}, [
                                                    h('div', {class:{'sch-dep__num': true}}, dep.name),
                                                    h('div', {class:{'sch-dep__times': true}}, [
                                                        h('v-badge', {props:{content: dep.n}},
                                                            m(dep.start).format('HH:mm') + ' - ' + m(dep.end).format('HH:mm')
                                                        ),
                                                        h('div', {class:{'sch-dep__tt':true}}, s)
                                                    ])
                                               ]),
                                               h('div',{class:{'sch-dep__modes': true}}, [
                                                   h('div', $utils.isEmpty(dep.season) ? null : dep.season),
                                                   h('div', $utils.isEmpty(dep.wkmode) ? null : dep.wkmode)
                                               ]),
                                               (dep.tt > 5*60)
                                                    ? h('div', {class: {'sch-dep__warn': true}}, [
                                                            h('jet-svg', {props: {xref: '#ico-warn'}}),
                                                            'требуется смена водителей'
                                                        ])
                                                    : null
                                           ]),
                                           h('v-card-actions', [
                                               h('v-btn', {props: {small: true}}, '-'),
                                               h('v-btn', {props: {small: true}}, '*')
                                           ])
                                       ])
                               ]);
                           })
                       ])
                    ]),     //v-col (4 - deps)
                    h('v-col', {props: {cols: this.has("deps") ? 9 : 12}, class: {'sch-schedules': true}}, [
                        h('v-toolbar', {
                            props: {dense: true, absolute: true, flat: true},
                            class: {"d-none": !(!!this.route)}
                        }, [
                            h('v-toolbar-title', [
                                (!!this.route)
                                    ? `графики движения по маршруту № ${ this.route.vcroutesRoutecode }. ${ this.route.vcroutesRoutename } 
                                       ${!(!!this.schVerId) ? ' (версия не выбрана)' : ''}`
                                    : null
                            ])
                        ]),
                        this.activeScheds.map((sch)=>{
                                if (!sch.stops){
                                    this.stops(sch);
                                }
                                var items = [];
                                if ((!!sch.stops) && (Array.isArray(sch.stops))) {
                                    sch.stops.map((stop, n)=>{
                                        items.push(
                                            h('v-list-item', {
                                                key: stop.id, 
                                                class:{"sch-stop": true, "sch-stop__miss": stop.miss}
                                            }, [
                                                h('v-list-item-icon', n + 1),
                                                h('v-list-item-content', {
                                                    style: stop.miss ? {color: "#ccc"} : {}                                                    
                                                }, [h('v-hover', {
                                                            scopedSlots: {
                                                                default: ({hover})=>{
                                                                    if (stop.miss) {
                                                                        return null;
                                                                    }
                                                                    const s = m(stop.arr).format('HH:mm') + ' ' + m(stop.dep).format('HH:mm');
                                                                    return h('div', {
                                                                                class: {
                                                                                            'sch-stop__time': true,
                                                                                            'unselectable': true,
                                                                                            'elevation-3': (!!hover)
                                                                                },
                                                                                on: {
                                                                                    click: ()=>{this.set_time(stop);}
                                                                                }
                                                                            }, [
                                                                            ( stop?.stop > 0) 
                                                                                ? h('v-badge', {
                                                                                    props: {content: stop.stop, color: 'yellow lighten-4'}
                                                                                }, s)
                                                                                : s
                                                                            ]
                                                                    );
                                                                }
                                                            }
                                                        }
                                                    ),
                                                    h('div',{class: {'sch-stop__name': true}}, stop.pt)
                                                ])
                                            ]),
                                            h('v-divider')
                                        );
                                    });      //sch.stops.map
                                    if ( (!!sch.season) || (!!sch.wkmode) ){
                                        items.push(
                                                h('v-list-item', {key: 'sch-modes-' + sch.id, class:{'sch-modes':true}}, [
                                                    h('v-list-item-icon',  [
                                                        h('v-icon', 'mdi-calendar-month')
                                                    ]),
                                                    h('v-list-item-content', [
                                                        $utils.isEmpty(sch.season) 
                                                            ? null
                                                            : h('div', sch.season),
                                                        $utils.isEmpty(sch.wkmode) 
                                                            ? null
                                                            : h('div', sch.wkmode)
                                                    ])
                                                ])
                                        );
                                        items.push( h('v-divider') );
                                    }
                                    
                                    s = (sch.tt > 60)
                                            ? Math.trunc(sch.tt/60) + ' ч. ' + (sch.tt - (Math.trunc(sch.tt/60 )*60)) + ' мин.'
                                            : sch.tt + ' мин.';
                                    items.push(
                                        h('v-list-item', {key: 'sch-toot-' + sch.id, class:{'sch-time':true}}, [
                                            h('v-list-item-icon',  [
                                                h('v-icon', 'mdi-clock-outline')
                                            ]),
                                            h('v-list-item-content', [
                                                'в пути: ' + s
                                            ])
                                        ])
                                    );
                                } else {
                                    items.push(h('v-list-item', {key:'sch-stub-' + sch.id}));
                                }
                                
                            return h('v-card', {
                                key: 'sch-' + sch.id,
                                class: {'sch-schedule': true, 'need-15': sch.need15, 'need-45': sch.need45}
                            }, [
                                h('v-card-text', [
                                    h('div', {class:{'sch-schedule__title': true}}, [
                                        h('div', {class: {'sch-schedule__code': true}}, sch.code),
                                        h('div', m(sch.start).format('HH:mm') + ' - ' + m(sch.end).format('HH:mm'))
                                    ]),
                                    h('v-list', {key:'sch-stops-' + sch.id}, items)
                                ])
                            ]);
                        })
                    ])  //v-col (9)
                ])  //v-row
                : null,
            h(SchVerDialog, {
                                props: {show: this.showVer, route: this.route},
                                on: {close:()=>{this.showVer = false;}}
                            }),
            h(SchFixDialog, {
                                props: {show: this.fixSche, route: this.route, verId: this.schVerId},
                                on: {close:()=>{this.fixSche = false;}}
                            }),
            h(SchTimeDialog, {
                                props: {show: this.showTime, stop: this.stop},
                                on: {close: ()=>{this.showTime = false;}}

                             }
            )
        ]);
    }
};
</script>

<style lang="scss">
    @import '~vuetify/src/styles/settings/_colors.scss';

    .sch-routs-place{
        width: 50rem;
        min-width: 50rem;
        display: flex;
        justify-content: space-between;
        & .jet-input{
            width: 24rem;
            & .v-text-field__details{
                min-height: 0;
                & .v-messages{display: none;}
            }
            & .v-label{display: none;}
        }
    }
    .mt-shedules{
        height: calc(100vh - 208px);
        & .row{
            height: 100%;
        }
        & .sch-deps{
            overflow: auto;
            height: 100%;
        }
        & .sch-dep{
            & .v-card__text{
                padding: 0 16px 16px 0;
            }
            & .v-badge__badge{
                border: 1px solid #fff !important;
            }
            &__num{
                border: 1px solid #fff;
                padding: 0.125rem 0.25rem;
                background: #9bafb8;
                border-radius: 4px 0;
                min-width: 4rem;
                box-shadow: 0 2px 4px rgba(0,0,0,0.18);
                color: #fff;
                align-self: flex-start;
            }
            &__times{
                padding: 0.125rem 0;
                line-height: 1.115;
            }
            &__modes{
                display: flex;
                justify-content: space-between;
                padding: 16px 0 0 16px;
            }
            &__active{
                background-color: var(--v-primary-base) !important;
                & .v-card__text {
                    color: #fff !important;
                }
            }
            &__warn{
                padding: 16px 0 0 16px;
                font-size: 0.8rem;
                color: var(--v-warning-base) !important;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                & svg{vertical-align: middle;margin-right: 0.5rem;}
            }
            &__tt{
                text-align: right;
                font-size: 0.85rem;
            }
        } /*sch-dep*/
        & .sch-schedules{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: auto;
            scrollbar-width: thin;
            align-self: flex-start;
            height: 100%;
            padding: 1rem;
            & .v-toolbar{
                top: 54px;
                width: 100%;
                .v-toolbar__title {
                    font-size: 0.9rem;
                    line-height: 1;
                }
            }
            & .sch-schedule{
                min-width: 20rem;
                margin-right: 1rem;
                margin-top: 54px;
                & .v-card__text {
                    height: 100%;
                    padding-bottom: 2rem;
                    overflow: auto;
                }
                & .v-list-item{
                    &__icon{
                        &:first-child{
                            margin-right: 0;
                        }
                    }
                    &__content{
                        display: flex;
                        & > *{
                            flex: 1 0 auto;
                        }
                    }
                    & .sch-stop{
                        &__time{
                            width: 3.5rem;
                            margin-right: 0.5rem;
                            white-space: normal;
                            padding: 0.25rem 0.5rem;
                            cursor: pointer;
                            text-align: center;
                            border-radius: 4px;
                            & .v-badge{
                                &__badge{color: #000;}
                            }
                        }
                        &__name{
                            width: calc(100% - 5rem);
                            white-space: normal;
                            overflow: hidden;
                        }
                    }
                    &.sch-modes, &.sch-time{
                        .v-list-item__content{
                            flex-direction: column;
                            align-items: flex-start;
                            padding-left: 0.5rem;
                        }
                    }
                }

                &__title{
                    position: absolute;
                    top: -16px;;
                    margin: 0 auto;
                    width: calc(100% - 32px);
                    display: flex;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 0.125rem 0.25rem;
                    justify-content: space-between;
                    background: #fff;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.18);
                }
                &__code{
                    color: #000;
                    font-weight: 500;
                }
                &.need-15{
                    & .sch-schedule__title{
                        background-color: var(--v-warning-lighten5);
                    }
                }
                &.need-45{
                    & .sch-schedule__title{
                        background-color: var(--v-warning-lighten1);
                    }
                }

            }
        }
    }
</style>
