<template>
  <v-container fluid align-start class="jet-map-conte">
    <v-layout fill-height style="position: relative">
      <v-flex shrink style="width: 400px; max-height: 100%;">
        <!-- Шапка управления списком -->
        <CarrierListHeader :loading="isLoading" 
                           :totals="totals" />
        <!-- Сам список ТС -->
        <v-list class="mt-vehicles-tree"
          style="overflow-y: scroll; height: calc(100% - 80px);"
          v-if="!isLoading && !_emptyObject(groupData)"
        >
          <!-- Проверка что это 2х уровневый список -->
          <template v-if="isTwoLevel">
            <v-list-group   append-icon="mdi-chevron-down"
                            v-for="(subItems, fTitle) in groupData" :key="fTitle"
                            v-model="expand[fTitle]">
              <template #activator>
                <v-list-item-title class="group-header">
                    <v-icon @click.stop="selectVehicles(fTitle)">
                          mdi-checkbox-{{ !!groupSelectedTwo[fTitle] ? 'marked' : 'blank' }}-outline
                    </v-icon>
                    <div class="text-truncate">{{ fTitle }} ({{subItems.length}})</div>
                </v-list-item-title>
              </template>
              <VehicleItem v-for="vehicle of subItems" 
                           :key="vehicle.id"
                           :vehicle="vehicle"
                           v-on:checked="vehiCheck(vehicle, $event)" />
            </v-list-group>
          </template>

          <!-- Иначе это 4х уровневый список -->
          <template v-else>
            <v-list-group
                append-icon="mdi-chevron-down"
                v-for="(items1, title1) in groupData" 
                :key="title1"
            >
              <template #activator>
                <v-list-item-title class="group-header">
                    <v-icon @click.stop="selectVehicles(title1, {level: 1, items: items1})">
                          mdi-checkbox-{{ groupSelectedThree[title1] ? 'marked' : 'blank' }}-outline
                    </v-icon>
                    <div class="text-truncate" :title="getTitle(items1, title1, 1)">{{ getTitle(items1, title1, 1) }}</div>
                </v-list-item-title>
              </template>

              <v-list-group
                sub-group no-action
                v-for="(items2, title2) in items1" 
                :key="title2"
              >
                <template #activator>
                  <v-list-item-title class="group-header">
                    <v-icon @click.stop="selectVehicles(title2, {level: 2, parent: title1, items: items2})">
                          mdi-checkbox-{{ groupSelectedThree[title2] ? 'marked' : 'blank' }}-outline
                    </v-icon>
                    <div class="text-truncate" :title="getTitle(items2, title2, 2)">{{ getTitle(items2, title2, 2) }}</div>
                  </v-list-item-title>
                </template>
                  <v-list-group
                    sub-group no-action
                    v-for="(items3, title3) in items2" 
                    :key="title3"
                  >
                    <template #activator>
                      <v-list-item-title>
                        <v-icon @click.stop="selectVehicles(title3, {level: 3, parent: title2, grandparent: title1, items: items3})">
                              mdi-checkbox-{{ groupSelectedThree[title3] ? 'marked' : 'blank' }}-outline
                        </v-icon>
                        <div class="text-truncate" :title="getTitle(items3, title3, 3)">{{ getTitle(items3, title3, 3) }}</div>
                      </v-list-item-title>
                    </template>
                    <VehicleItem v-for="vehicle of items3" 
                                 :key="vehicle.id"
                                 sub-group no-action
                                 :vehicle="vehicle" 
                                 v-on:checked="vehicleCheck(vehicle, $event)" />
                  </v-list-group>
              </v-list-group>
            </v-list-group>
          </template>
        </v-list>

        <!-- Отображение загрузки списка данных -->
        <v-layout
          fill-height justify-center align-center
          v-if="isLoading"
        >
          <v-progress-circular indeterminate/>
        </v-layout>

        <!-- Если нет данных -->
        <v-layout
          fill-height justify-center align-center
          v-if="!isLoading && _emptyObject(groupData)">
          Нет данных для отображения
        </v-layout>
      </v-flex>

        <MapSplit class="fill-height map_splitter" 
               direction="vertical" ref="nSplit"
               @updated="onSplit"
               @onDragEnd="onSplit">
          <!-- Блок карты -->
          <SplitArea :size="journalMinimized ? 95 : 64" id="MapParent" 
                     style="position:relative;">
            <!-- Используем MapBox -->
            <MapBoxMapProvider ref="nMap" />
            <!-- Дополнительные инструменты -->
            <div style="position: absolute; top: 20px; right: 20px;">
              <!-- Слои на карте -->
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <MapLayers
                    v-on="on"
                    v-model="layers"
                    @onSubmit="onDrawLayers"
                  ></MapLayers>
                </template>
                <span>Слои на карте</span>
              </v-tooltip>

              <!-- Скриншот -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-dialog
                    max-width="800"
                    v-model="screenShotDialog"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        fab small dark
                        style="visibility: hidden; display: none;"
                        color="primary"
                        elevation="2"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-camera
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-toolbar flat>
                        <v-toolbar-title>
                          Скриншот события
                        </v-toolbar-title>
                        <v-spacer/>
                        <v-btn icon @click="screenShotDialog = false">
                          <v-icon color="grey">
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-toolbar>

                      <v-divider/>

                      <v-card-text class="pa-4">
                        <v-layout column>
                          <v-flex>
                            <v-layout>
                              <v-flex>
                                Тип нарушения
                              </v-flex>

                              <v-flex>
                                <v-select
                                  item-text="text"
                                  item-value="value"
                                  v-model="screenShotViolation"
                                  :items="screenShotViolations"
                                ></v-select>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <v-flex>
                            <v-textarea
                              title="Комментарий"
                              v-model="screenShotComment"
                            ></v-textarea>
                          </v-flex>
                        </v-layout>
                      </v-card-text>

                      <v-divider/>

                      <v-card-actions class="px-3">
                        <v-btn
                          color="primary"
                          :disabled="screenShotMaking"
                          :loading="screenShotMaking"
                          @click="makeScreenShot"
                        >
                          Скриншот
                        </v-btn>
                      </v-card-actions>

                      <div id="r_image"></div>
                    </v-card>
                  </v-dialog>
                </template>
              </v-tooltip>
            </div>
            <map-popup ref="nPopup" />
          </SplitArea>
          <SplitArea :size="journalMinimized ? 8 : 39">
            <tele-journal ref="nJournal" @minimized="onJournalMinimized"/>
          </SplitArea>
        </MapSplit>
    </v-layout>
  </v-container>
</template>

<script>
import { Split } from 'vue-split-panel';
import MapBoxMapProvider from '@/components/MapBoxMapProvider';
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';
import CarrierListHeader from './components/CarrierListHeader';
import VehicleService from '@/services/VehicleService';
import {default as TeleJournal, navStatus} from '@/components/dev/components/TeleJournal';
import VehicleItem from './components/VechileItem';
import CarrierUtils from '@/components/dev/service/CarrierUtils';
import MapSettingsService from '@/components/dev/service/MapSettingsService';
import MapPopup from '@/components/dev/components/MapPopup';
import MapLayers from './components/MapLayers';
import MapLayersService from '@/services/MapLayersService';
import { formatDate } from '@/utils/utils';
const $moment = require('moment');

var WS_URI = {
    servers: process.env.VUE_APP_BACKEND_NATS_SERVER,
    user: process.env.VUE_APP_BACKEND_NATS_USERNAME,
    pass: process.env.VUE_APP_BACKEND_NATS_PASSWORD
};

// Типы последних событий
const _lastEvents = CarrierUtils.lastEvents();

// Набор ТС
let _vehicles = [];
// Текущая группировка
let _currentGroup = 'GRP_BY_CARRIERS';

let _codec = null;
let _nats = null;
let _sids = {};

const MapSplit = {
    extends: Split,
    watch: {
        instance(i){
            if (!!i){
                this.$emit("updated");
            }
        }
    }
};

/**
 * Компонент композитора
 * Управляет всем, что находится на странице "Карта"
 *
 * Через него должны проходить любые действия связанные с отображением и изменением данных
 */
export default {
  name: 'ptMonMap',
  mixins: [
    HideGlobalToolbarMixin,
  ],
  components: {
    MapSplit,
    MapPopup, 
    MapLayers,
    TeleJournal, 
    CarrierListHeader,
    MapBoxMapProvider, 
    VehicleItem
  },
  data() {
    return {
      // Флаг что происходит загрузка
      isLoading: false,

      // Сгруппированные данные
      groupData: [],
      // Информация о раскрытых данных 2го уровня списка
      expand: {},
      // Информация о раскрытых данных 3го уровня списка
      subExpand: {},
      // Выбор всех ТС группы 2го уровня
      groupSelectedTwo: {},
      // Выбор всех ТС группы 3го уровня
      groupSelectedThree: {},

      // Слои на карте
      layers: {
        itineraries: [],
        geozones: [],
        stops: [],
        routeObjects: [],
      },
      // Пока диалога для скриншота
      screenShotDialog: false,
      // Флаг что скриншот делается
      screenShotMaking: false,
      // Комментарий к скриншоту
      screenShotComment: '',
      // Тип нарушения
      screenShotViolation: '',
      // Набор нарушений
      screenShotViolations: [
        { text: 'Прибытие', value: 'successArrival' },
        { text: 'Не прибытие', value: 'failedArrival' },
        { text: 'Отправление', value: 'successDeparture' },
        { text: 'Не отправление', value: 'failedDeparture' },
        { text: 'Без движения', value: 'noMovement' },
        { text: 'Восстановление движения', value: 'movement' },
        { text: 'Сход с маршрута', value: 'routeExit' },
        { text: 'Возвращение на маршрут', value: 'routeEnter' },
        { text: 'Без навигационного блока', value: 'noNaviDevice' },
        { text: 'Без данных телеметрии', value: 'noNaviData' },
        { text: 'Состояние рейса', value: 'tripState' },
      ],
      lastDevice: null,
      // Проверка что это 2х уровневый список
      isTwoLevel: true,
      selectedRoutes: new Set(),
      // Флаг что журнал свернут
      journalMinimized: true,
      totals: null  //selected vehicles info
    };
  },
  computed: {
    isActive(){
        return /^(карта)+/i.test(this.$store.state.colls.active?.name);
    },
  },    //computed
  provide() {
    const self = this;
    return {
      // Установки точки на карту
      placePoint(vehicle) {
        self.placePoint(vehicle);
      },
      // Удаление точки на карте
      removePoint(vehicle) {
        self.removePoint(vehicle);
      },
      // Отрисовка трека машинки
      async drawTrack(vehicle, start = new Date(), end = new Date()) {
        await self.drawTrack(vehicle, start, end);
      },
      async drawTrackingLine(vehicle, points) {
          await self.drawTrackingLine(vehicle, points);
      },
      // Удаление трека
      removeTrack(vehicle) {
        self.removeTrack(vehicle);
      },
      // Удаление всех треков
      removeTrackTotal() {
        self.removeTrackTotal();
      },

      // Начало сллежения
      startTracking(vehicle) {
        self.startTracking(vehicle);
      },
      // Стоп слежения
      stopTracking(vehicle) {
        self.stopTracking(vehicle);
      },
      
      // Отмена слежения за всеми ТС
      stopTrackingTotal() {
        self.stopTrackingTotal();
      },

      // Смена настроек отображения
      changeTrackStyle(vehicle, settings) {
        self.changeTrackStyle(vehicle, settings);
      },

      // ----- CarrierListHeader -----

      // Смена группировки
      changeGroup(groupType) {
        self.changeGroup(groupType);
      },
      // Фильтрация
      changeFilter(filter) {
        self.changeFilter(filter);
      },
      // Выбор всех(2)/запланированных(1)/сброс(0)
      selectAll(show = 0) {
        self.selectAll(show);
      },
      clearAll() {
        self.clearAll();
      },

      // ----- Journal -----

      // Подлет карты к точке трека
      flyTo(vehicle, trackPoint) {
        self.flyTo(vehicle, trackPoint);
      },
      // Установка фокуса на координатах
      flyToCoordinates(lat, lon) {
        self.flyToCoordinates(lat, lon);
      },
      
      // Показ попапа
      popupShow(vehicle, trackPoint) {
        self.popupShow(vehicle, trackPoint);
      },
      // Внедрнение MapProvider
      // TODO: такое себе, но пока что лучшее, что можно было придумать
      diMapProvider() {
        return self.$refs['nMap'];
      },

      // ----- FROM MAP -----

      // Клик по точке трека на карте
      trackPointClick(vehicle, point) {
        self.trackPointClick(vehicle, point);
      },
      // Клик по статичной машинке
      vehiclePointClick(vehicle, point) {
        self.vehiclePointClick(vehicle, point);
      },
    };
  },
  async created() {
    _codec = await jet.http.getJSONCodec();
    _nats = await jet.http.getNats(WS_URI);
  },
  mounted(){
    setTimeout(()=>{
      $('.v-window-item--active .jet-collection-conte.jet-collection-master').css({height: '100%'});
    }, 300);
    this.getData();
  },
  destroyed() {
    if (_sids != null) {
      Object.keys(_sids).forEach(vId => {
        _sids[vId].unsubscribe();
      });
    }
    _codec = null;
    _nats = null;
  },
  methods: {
    async getData() {
      this.isLoading = true;

      let prevehicles = await VehicleService.getVehiclesForMap();
      let vehicles = JSON.parse(prevehicles[0].jsonobject.value);
      vehicles = vehicles.filter(it => it.origid != null);
      vehicles = await this.getRent(vehicles);
      
      const statuses = await VehicleService.getStatuses(vehicles.map(it => it.id));
      if (statuses != null && statuses !== {} && Object.keys(statuses).length) {
        vehicles = vehicles.map(it => {
          it.last = statuses[it.id];
          return it;
        });
      }
      
      const blocks = await VehicleService.getBlockStatus(vehicles.map(it => '"' + it.id + '"').toString());
      vehicles = vehicles.map(it => {
        if (blocks != null && blocks !== [] && Object.keys(blocks).length) {
          const vehicleStatus = blocks.find(block => block.id === it.id);
          it.blockStatus = vehicleStatus ? vehicleStatus.blockstatus : false;
        } else {
          it.blockStatus = false;
        }
        return it;
      });
      
      _vehicles = _copy(vehicles);
      _vehicles.map( v => {
            v.dispAttrs = {
                  draw: false,
                  track: false,
                  tracking: false,
                  at: false //time for changes
            };  //display modes: check, tracking & etc...
      });
      const preData = await CarrierUtils.onGroupData('GRP_BY_CARRIERS', _vehicles);
      this.groupData = preData.data;
      this.isTwoLevel = preData.level === 2;
      
      this.isLoading = false;
    },
    getTitle(items, title, level){
        return `${title} (${this.getCountByLevel(items, title, level)})`;
    },
    getCountByLevel(items, title, level) {
      let count = 0;
      switch (level) {
        case 1:
          Object.keys(items).forEach(key => {
            const subItem = items[key];
            Object.keys(subItem).forEach(subKey => {
              count += subItem[subKey].length;
            });
          });
          break;
        case 2:
          Object.keys(items).forEach(key => {
            count += items[key].length;
          });
          break;
        case 3:
          count = items.length;
          break;
        }
        return count;
    },
    // Получение информации о аренде машин
    async getRent(vehicles) {
      if (!(!!vehicles)) {
        return;
      }

      const rent = await VehicleService.getCarrierRent();

      vehicles.map((vc) => {
        const idx = rent.findIndex(r => r.carrierid === vc.acid);

        if (idx > -1) {
          vc['rent'] = rent[idx];
        }
      });

      return vehicles;
    },
    onJournalMinimized(isMin) {
        this.journalMinimized = isMin;
        this.$nextTick(()=>{
            this.$refs['nMap'].update();
        });
    },
    onSplit(){
        const map = this.$refs['nMap'];
        if (!!map){
            map.update();
        }
    },

    // -------------------

    // Нарисовать точку
    placePoint(vehicle) {
      return this.$refs['nMap'].drawVehicle(vehicle);
    },
    // Удалить точку
    removePoint(vehicle) {
      return this.$refs['nMap'].removeVehicle(vehicle);
      this.$refs['nPopup'].hide();
    },
    // Установить иконку-флаг на выделенной точке трека
    placeFlag(trackPoint) {
      return this.$refs['nMap'].drawFlag(trackPoint);
    },
    // Нарисовать трек по слежению
    async drawTrackingLine(vehicle, dateStart, dateEnd){
      try {
        // Рисуем трек на карте
        const settings = MapSettingsService.getSettings(vehicle);
        this.$refs['nMap'].drawTrackingLine(vehicle, vehicle.track, settings);
        vehicle.trips = null;
        try {
          vehicle.trips = await VehicleService.getVcTrips(vehicle, {start: dateStart, end: dateEnd});
        } catch(e){
          console.log('ERR on (trips)', e);
        }
        const i = _vehicles.findIndex(v => {return (v.id === vehicle.id);});
        if ( i > -1 ){
          _vehicles[i].track = vehicle.track;
          _vehicles[i].trips = vehicle.trips;
        };
      } catch(e){
        console.log('ERR (on track)', e);
        jet.msg({text:"Ошибка при загрузке трека, попробуйте обновить страницу", color: "warning"});
      }
    },
    // Нарисовать трек
    async drawTrack(vehicle, dateStart, dateEnd){
        try {
            const track = await VehicleService.getHistoryTrack(vehicle, dateStart, dateEnd);
            const points = track[0]?.points || [];  //fix
            const markers = VehicleService.getTrackMarkers(track, points);
            const settings = MapSettingsService.getSettings(vehicle);
            vehicle.track = track;
            if (points.length > 0) {
              // Рисуем трек на карте
              this.$refs['nMap'].drawTrack(vehicle, points, markers, settings);
              this.$refs['nJournal'].setData(vehicle, points);
              vehicle.trips = null;
              try {
                  vehicle.trips = await VehicleService.getVcTrips(vehicle, {start: dateStart, end: dateEnd});
              } catch(e){
                  console.log('ERR on (trips)', e);
              }
              const i = _vehicles.findIndex(v => {return (v.id === vehicle.id);});
              if ( i > -1 ){
                    _vehicles[i].track = vehicle.track;
                    _vehicles[i].trips = vehicle.trips;
              };
            } else {
                jet.msg({
                            text: `${vehicle.govnum?.toUpperCase() || 'б/н'} за период ${ $moment(dateStart).format('DD.MM.YYYY HH:mm')} - 
                               ${ $moment(dateEnd).format('DD.MM.YYYY HH:mm')} данные трека не получены`, 
                            color: "warning",
                            timeout: 30000
                        });
            }
        } catch(e){
            console.log('ERR (on track)', e);
            jet.msg({text:"Ошибка при загрузке трека, попробуйте обновить страницу", color: "warning"});
        }
    },
    // Удалить трек
    removeTrack(vehicle) {
      this.$refs['nMap'].removeTrack(vehicle);
      this.$refs['nMap'].removeFlag();
      this.$refs['nJournal'].setData(vehicle, []);
      this.$refs['nPopup'].hide();
    },
    // Удаление всех треков
    removeTrackTotal() {
      this.$refs['nMap'].clearTracks();
      this.$refs['nMap'].removeFlag();
      this.$refs['nJournal'].setData(null, []);
      this.$refs['nPopup'].hide();
    },

    // Начало слежения
    async startTracking(vehicle) {
      // Сразу покажем машинку, пока ждем очередного пакета
      this.removePoint({
        ...vehicle,
        tracking: true,
      });
      
      if (vehicle.last?.lat != 0) {
        this.flyToCoordinates(vehicle.last.lat, vehicle.last.lon, 17);
      } 
      
      const ls = await VehicleService.getLastInfo([vehicle.id]);

      if (ls === false) {
        jet.msg(`Для ${vehicle.govnum} нет информации`);
        return null;
      }

      vehicle.last = ls[0];
      
      var track = [];
      track.push({...ls[0]});
      vehicle.track = track;
      
      this.$refs['nMap'].clearTracks();
      this.$refs['nJournal'].setData(vehicle, track);
      
      this.lastDevice = vehicle.origid;
      
      this.placePoint({
        ...vehicle,
        ll: `${vehicle.last.lat}:${vehicle.last.lon}`,
        heading: vehicle.last.heading + 180,
        tracking: true,
      });

      this.flyToCoordinates(vehicle.last?.lat, vehicle.last?.lon, 17);

      try {
          //if (!(!!_nats)) {
            _sids[vehicle.id] = _nats.subscribe(`PUBLIC.kigat.telemetry.${vehicle.id}`);
            (async ()=>{
              for await (const m of _sids[vehicle.id]) {
                try{
                  const payload = _codec.decode(m.data);
                  
                  if (payload.time > vehicle.track[vehicle.track.length - 1].time && this.lastDevice === payload.originalId) {
                    vehicle.track.push(payload);
                    this.$refs['nJournal'].addData(vehicle, payload);
                    this.drawTrackingLine(vehicle, new Date (vehicle.last.time), new Date (payload.time));
                  }
                  
                  // TODO: Возможно не самое лучшее решение
                  this.removePoint({
                    ...vehicle,
                    tracking: true,
                  });

                  vehicle.lasttime = payload.time;

                  vehicle.last.lat    = payload.lat;
                  vehicle.last.lon    = payload.lon;
                  vehicle.last.time   = payload.time;
                  vehicle.last.speed  = payload.speed;
                  vehicle.last.status = (payload.speed > 0.5) ? 'moving' : 'stop';

                  this.placePoint({
                    ...vehicle,
                    ll: `${payload['lat']}:${payload['lon']}`,
                    heading: payload.heading + 180,
                    tracking: true,
                  });

                  if (Object.keys(_sids).length < 3){
                      this.flyToCoordinates(payload['lat'], payload['lon']);
                  }
                } catch (e) {
                  console.error('Nats parse error:', e);
                }
              }
            })();
          //}
      } catch (e) {
        console.error('Tracking error', e);

        jet.msg({
          text: 'Произошла ошибка во время запроса слежения за ТС',
        });
      }

      return vehicle;
    },
    // Остановка слежения
    stopTracking(vehicle) {
      if (!!_sids[vehicle.id] && _sids[vehicle.id] != null) {
        _sids[vehicle.id].unsubscribe();

        delete _sids[vehicle.id];
      }

      vehicle = this.removePoint(vehicle);
      
      this.removeTrack(vehicle);

      return vehicle;
    },
    
    // Остановка слежения за всеми ТС
    stopTrackingTotal() {
      Object.keys(_sids).forEach(vId => {
        if (!!_sids[vId] && _sids[vId] != null) {
          _sids[vId].unsubscribe();
        }

        delete _sids[vId];
      });
      this.$refs['nMap'].clearVehicles();
    },

    // Применение настроек
    changeTrackStyle(vehicle, settings) {
      this.$refs['nMap'].changeTrackStyle(vehicle, settings);
    },

    // -------------------

    // Смена группировки
    async changeGroup(groupType) {
      this.isLoading = true;

      this.clearAll();

      this.groupData = [];
      const preData = await CarrierUtils.onGroupData(groupType, _vehicles);
      this.groupData = preData.data;
      this.isTwoLevel = preData.level === 2;

      _currentGroup = groupType;

      if (_sids != null) {
        Object.keys(_sids).forEach(vId => {
          if (!!_sids[vId] && _sids[vId] != null) {
            _sids[vId].unsubscribe();
          }

          delete _sids[vId];
        });
      }

      this.isLoading = false;
    },
    // Фильтр
    async changeFilter(filter) {
      this.isLoading = true;

      let vehicles = [];

      // Если есть поисковая строка
      if (filter && (filter.text !== '' || filter.text != null)) {
        const re = new RegExp(filter.text, 'i');

        vehicles = _vehicles.filter(it => {
          return re.test(it.orgname) || re.test(it.govnum);
        });
      }
      
      if (filter.text == null || filter.text === '') {
        // Если не было поисковой строки То берем все машинки
        vehicles = _copy(_vehicles);
      }

      // Обработка фильтра "Статуса ТС"
      if (filter && filter.statuses) {
        const statuses = filter.statuses;

        vehicles = vehicles.filter(it => {
          // На Связи
          if (statuses.inState && it.state === 0) {
            return it;
          }

          // 2 - 24 часа
          if (statuses.fail2to24 && it.state === 24) {
            return it;
          }

          // 24 - 72 часов
          if (statuses.fail24to72 && it.state === 72) {
            return it;
          }

          // 72 - 100 - часов
          if (statuses.failUp72 && it.state === 100) {
            return it;
          }

          // Нет данных
          if (statuses.noData && it.state === 9999) {
            return it;
          }

          return null;
        }).filter(it => it != null);
      }

      // Обработка фильтра "Статус движения"
      if (filter && filter.moving) {
        const moving = filter.moving;

        vehicles = vehicles.filter(it => {
          // TODO: _lastEvents.moving -> залипуха, чтобы работало без загрузки всей инфы
          const lastStatus = (it.last?.status || _lastEvents.moving).toLowerCase();

          // Движется
          if (moving.move && lastStatus === _lastEvents.moving) {
            return it;
          }

          // Остановка
          if (moving.stop && lastStatus === _lastEvents.stop) {
            return it;
          }

          // Парковка
          if (moving.parking && lastStatus === _lastEvents.parking) {
            return it;
          }

          // Заблокирован
          if (moving.blocked && lastStatus === _lastEvents.blocked) {
            return it;
          }

          // Тревожная кнопка TODO: подумать
          if (moving.alertButton && lastStatus === _lastEvents.alarm) {
            return it;
          }

          return null;
        }).filter(it => it != null);
      }

      const preData = await CarrierUtils.onGroupData(_currentGroup, vehicles);
      this.groupData = preData.data;
      this.isTwoLevel = preData.level === 2;

      this.isLoading = false;
    },
    /**
     * Выбор 
     * @param {integer} show всех(2)/запланированных(1)/сброс(0)
    */ 
    selectAll(show = 0) {
      this.clearAll();
      if (0 === show){
          return ;
      }
      const re = /^(?:не)+/i;
      
      var n = 0, 
          _show;
      _vehicles.filter((p) => !p.blockStatus).map(v=>{
          _show = (
                        (show === 2) 
                      ||(
                            (show < 2) 
                          &&(!!v.routecode) && !re.test(v.routecode)
                        )
                  );
          if (_show){
            n++;
            this.groupSelectedTwo[v.orgname] = true;
            this.startTracking(v).then(e => {
              v.dispAttrs = {
                ...v.dispAttrs || {},
                draw: true,
                tracking: (!!e),
                at: (new Date()).getTime()
              };
            });
          }
      });
      
      this.totals = {
          all: (show === 2) ? n : 0,
          planned: (show === 1) ? n : 0
      };
      
    },  //
    // Очистка всего
    clearAll() {
      this.expand = {};
      this.subExpand = {};

      this.groupSelectedTwo = {};
      this.groupSelectedThree = {};

      this.$refs['nMap'].clearVehicles();
      this.$refs['nMap'].clearTracks();
      this.$refs['nMap'].removeFlag();
      this.$refs['nJournal'].setData(null, []);
      this.$refs['nPopup'].hide();
      this.totals = null;
      this.onJournalMinimized(true);

      if (this.isTwoLevel) {
        for (const key in this.groupData) {
            for (const v of this.groupData[key]) {
              if (!!v.dispAttrs?.tracking) {
                this.stopTracking(v);
              }
  
              v.dispAttrs = {
                  ...v.dispAttrs,
                  draw: false,
                  track: false,
                  tracking: false,
                  at: (new Date()).getTime()
              };
            }
          }
      } else {
          var vehiclesTmp = [];
            Object.keys(this.groupData).forEach(key => {
              const item1 = this.groupData[key];
              Object.keys(item1).forEach(key1 => {
                const item2 = item1[key1];
                Object.keys(item2).forEach(key2 => {
                    vehiclesTmp = vehiclesTmp.concat(item2[key2]);
                });
              });
            });
            this.stopTrack(vehiclesTmp);
      }
    },

    // Подлет карты
    flyTo(vehicle, trackPoint) {
      this.$refs['nMap'].flyTo(vehicle, trackPoint);
    },
    
    // Установка фокуса на координатах
    flyToCoordinates(lat, lon, zoom){
        if (
                (!!lat)&&(!!lon)
           ){
            const map = this.$refs['nMap'];
            if (typeof zoom !== "undefined"){
                map.setZoom(zoom);
            }
            map.flyToCoordinates(lat, lon);
        }
    },

    // -------------------

    // Обработка клика по точке трека на карте
    trackPointClick(vehicle, trackPoint) {
      this.$refs['nJournal'].scrollTo(vehicle, trackPoint);
      this.flyTo(vehicle, trackPoint);
      this.popupShow(vehicle, trackPoint);
    },
    
    // Обработка клика по статической машинке
    vehiclePointClick(vehicle, point) {
      
      console.log('last info: ', vehicle);
      
      let status = 'Нет данных';
      
      switch (vehicle.last.status?.toLowerCase() || 'n/d') {
        case _lastEvents.moving:
          status = 'Движется';
          break;
        case _lastEvents.blocked:
          status = 'Заблокирован';
          break;
        case _lastEvents.alarm:
          status = 'Тревожная кнопка';
          break;
        case _lastEvents.noData:
          status = 'Нет данных';
          break;
        case _lastEvents.parking:
          status = 'Парковка';
          break;
        case _lastEvents.speedUp:
          status = 'Превышение скорости';
          break;
        case _lastEvents.stop:
          status = 'Стоянка';
          break;
      }
      
      //TODO: 
      const info = [
        `Перевозчик: ${vehicle.orgname || 'Не указано'}`,
        `Маршрут: ${ (!!vehicle.routename) ? '№ ' + vehicle.routecode + '. ' + vehicle.routename : 'отсутствует'}`,
        `Событие: ${status} ${ (vehicle.last.speed > 0.5) ? ' (' + vehicle.last.speed + ' км/ч)' : '' }`,
        `Положение: ${Number(vehicle.last.lat).toFixed(5)}; ${Number(vehicle.last.lon).toFixed(5)}`,
      ];
      
      const popa = this.$refs['nPopup'];
      if (!!popa) {
          popa.set({
              title: `${vehicle.vctypename} ${vehicle.vckindname} ${(vehicle.govnum || '').toUpperCase()}`,
              text: info.join('<br />'),
              time: vehicle.lasttime,
              point
          });
      }
    },
    //Инфоблок по точке трека ТС
    popupShow(vehicle, trackPoint) {
        this.placeFlag(trackPoint);
        
        var route = null,
            trip = null;
        const _tm = $moment(trackPoint.time);
        vehicle.trips?.map((t)=>{
            if ( 
                    (!route)
                  &&(_tm.isSameOrBefore($moment(t.endtrip)))
               ){
                route = t;
            }
            if (_tm.isBetween($moment(t.starttrip), $moment(t.endtrip))){
                route= t;
                trip = t;
            }
        });
        
        const status = (!!trackPoint.speed && trackPoint.speed > 0.5)
          ? `В движении, скорость ${parseInt(trackPoint.speed)} км/ч`
          : navStatus(trackPoint);

        const info = [
          (!!trackPoint.violation) ? `<h3 class="red--text">${trackPoint.violation.name}</h3>` : '',
          `Перевозчик: ${vehicle.orgname || ''}`,
          `Маршрут: ${ (!!route?.route) ? route.routeRoutecode + '. ' + route.routeRoutename : 'отсутствует'}`,
          `Выезд: ${ (!!trip?.name) ? '№' + trip?.name : 'отсутствует'}`,
          `Рейс: ${ (!!trip?.schedule) ? '№' + trip?.scheduleTripcode : 'отсутствует'}`,
          `Событие: ${ status }`,
          `Положение: ${Number(trackPoint.lat).toFixed(5)} / ${Number(trackPoint.lon).toFixed(5)} ${ !!trackPoint.place ? ' (' + trackPoint.place + ')' : '' }`,
        ];
        if (vehicle.trips?.length < 1){
            info.push('<b>не планировалась</b>');
        }

        const popa = this.$refs['nPopup'];
        if (!!popa) {
            popa.set({
              title: `${vehicle.vctypename} ${vehicle.vckindname} ${(vehicle.govnum || '').toUpperCase()}`,
              text: info.join('<br />'),
              time: trackPoint.time,
              point: trackPoint.point
            });
        }
    },

    // ------------------- СЛОИ -------------------

    // При выборе отображения слоев на карте
    onDrawLayers(data) {
      const itineraries = data.itineraries || [];
      const stops = data.stops || [];
      const geoZones = data.geozones || [];
      const stopTypes = data.routeObjects || [];

      this._drawRoutes(itineraries.map(it => it.id));
      this._drawStops(stops);
      this._drawGeoZones(geoZones);
      this._drawStopObjects(stopTypes);
    },
    // Создание скриншота
    makeScreenShot() {
      // TODO: realize
    },
    // Отрисовка маршрута
    async _drawRoutes(ids) {

      // TODO: пока приходит только 1
      if (ids.length) {
        const promises = [];

        ids.forEach(it => {
          promises.push(
            MapLayersService.getRoutePoint(it),
          );
        });

        Promise.all(promises)
          .then(responses => {
            const routes = [];

            responses.forEach((it, index) => {
              routes.push({
                id: ids[index],
                points: it,
              });
            });

            this.$refs['nMap'].drawLayerRoutes(routes);
          });
      } else {
        this.$refs['nMap'].drawLayerRoutes([]);
      }
    },
    // Отрисовка остановок
    _drawStops(stops) {
      this.$refs['nMap'].drawStops(stops);
    },
    // Отрисовка гео зон
    _drawGeoZones(geoZones) {
      geoZones = geoZones || [];

      this.$refs['nMap'].drawGeoZones(
        geoZones.map(it => MapLayersService.parseWkx(it)),
      );
    },
    // Отрисовка объектов маршрута
    _drawStopObjects(stopObjects) {
      this.$refs['nMap'].drawStopObjects(stopObjects);
    },

    // --------------------------------------------

    // Выбор всех ТС группировки
    selectVehicles(title, params = { level: 1, parent: null, grandparent: null, items: null }) {
      // Если это 2й уровень
      if (this.isTwoLevel) {
        // Если ранее не выбирали
        if ( !this.groupSelectedTwo.hasOwnProperty(title) ) {
          // Помечаем как выбранное
          this.groupSelectedTwo[title] = true;
          this.expand[title] = true;
          this.$forceUpdate();
          const vehicles = this.groupData[title] || [];
          this.startTrack(vehicles);
        } else {
          delete this.groupSelectedTwo[title];
          const vehicles = this.groupData[title] || [];
          this.$forceUpdate();
          this.stopTrack(vehicles);

          // this.$set(this.groupData, title, vehicles);
        }
        //this.$set(this, 'groupSelectedTwo', gst);
      } else {
        // Если это 4й уровень
        const gsth = _copy(this.groupSelectedThree);
        var vehiclesTmp = [];
        
        switch (params.level) {
            case 1:
                Object.keys(params.items).forEach(key => {
                  if (!!gsth[title]) {
                      delete gsth[key];
                  } else {
                      gsth[key] = true;
                  }
                  const subItem = params.items[key];
                  Object.keys(subItem).forEach(subKey => {
                      if (!!gsth[title]) {
                          delete gsth[subKey];
                      } else {
                          gsth[subKey] = true;
                      }
                      vehiclesTmp = vehiclesTmp.concat(subItem[subKey]);
                  });
                });
                break;
            case 2:
                Object.keys(params.items).forEach(key => {
                  if (!!gsth[title]) {
                      delete gsth[key];
                  } else {
                      gsth[key] = true;
                  }
                  vehiclesTmp = vehiclesTmp.concat(params.items[key]);
                });
                break;
            case 3:
                vehiclesTmp = vehiclesTmp.concat(params.items);
                break;
            default:
                vehiclesTmp = [];
                break;
            }
        //если отмечена галка
        if (!!gsth[title]) {
            if (!!params.parent) {
                if (params.level === 2) {
                    var checked = false;
                    Object.keys(this.groupData[params.parent]).forEach(key => {
                        if (key in gsth && key !== title) {
                            checked = true;
                        }
                    });
                    if (!checked) {
                        delete gsth[params.parent];
                    }
                } else if (params.level === 3) {
                    var checked = false;
                    const item = this.groupData[params.grandparent];
                    Object.keys(item).forEach(key => {
                        const subItem = item[key];
                        Object.keys(subItem).forEach(subKey => {
                            if (subKey in gsth && subKey !== title) {
                                checked = true;
                            }
                        });
                    });
                    if (!checked) {
                        delete gsth[params.parent];
                    }
                }
            }
            if (!!params.grandparent) {
                if (params.level === 3) {
                    var checked = false;
                    Object.keys(this.groupData[params.grandparent]).forEach(key => {
                        if (key in gsth) {
                            checked = true;
                        }
                    });
                    if (!checked) {
                        delete gsth[params.grandparent];
                    }
                }
            }
            
            delete gsth[title];
            this.$set(this, 'groupSelectedThree', gsth);
            this.stopTrack(vehiclesTmp);
        //если не отмечена галка
        } else {
            if (!!params.parent) {
                gsth[params.parent] = true;
            }
            if (!!params.grandparent) {
                gsth[params.grandparent] = true;
            }
            gsth[title] = true;
            this.$set(this, 'groupSelectedThree', gsth);
            this.startTrack(vehiclesTmp);
        }
      }
    },  //selectVehicles
    startTrack(vehicles){
        vehicles.forEach(vehicle => {
            if (vehicle.blockStatus === false) {
              this.startTracking(vehicle).then(e => {
                vehicle.dispAttrs = {
                  ...vehicle.dispAttrs || {},
                  draw: true,
                  tracking: (!!e),
                  at: (new Date()).getTime()
                };
              });
            }
          });
        vehicles
          .map(it => it.routeid)
          .filter(it => it != null)
          .filter((it, index, self) => self.indexOf(it) === index)
          .forEach(route => {
            this.selectedRoutes.add(route);
        })
        this._drawRoutes(Array.from(this.selectedRoutes));
    },
    stopTrack(vehicles){
        vehicles.forEach(vehicle => {
            this.stopTracking(vehicle);

            vehicle.dispAttrs = {
              ...vehicle.dispAttrs || {},
              draw: false,
              tracking: false,
              at: (new Date()).getTime()
            };
          });
        
        vehicles
          .filter(it => !this.groupSelectedThree.hasOwnProperty(it.fullroutename))
          .map(it => it.routeid)
          .filter(it => it != null)
          .filter((it, index, self) => self.indexOf(it) === index)
          .forEach(route => {
            this.selectedRoutes.delete(route);
        })
        this._drawRoutes(Array.from(this.selectedRoutes));
    },
    vehicleCheck(vehicle, event){
        const gsth = _copy(this.groupSelectedThree);
        if (event){
            this.selectedRoutes.add(vehicle.routeid);
            this._drawRoutes(Array.from(this.selectedRoutes));
            
            Object.keys(this.groupData).forEach(key1 => {
                var checked1 = false;
                const item1 = this.groupData[key1];
                Object.keys(item1).forEach(key2 => {
                    var checked2 = false;
                    const item2 = item1[key2];
                    Object.keys(item2).forEach(key3 => {
                        const item3 = item2[key3];
                        if (item3.find(it => it === vehicle)){
                            checked1 = true;
                            checked2 = true;
                            gsth[key3] = true;
                        }
                    });
                    if (checked2) {
                        gsth[key2] = true;
                    }
                });
                if (checked1) {
                    gsth[key1] = true;
                }
            });
            this.$set(this, 'groupSelectedThree', gsth);
            
        } else {
            
            Object.keys(this.groupData).forEach(key1 => {
                var checked1 = false;
                const item1 = this.groupData[key1];
                Object.keys(item1).forEach(key2 => {
                    var checked2 = false;
                    const item2 = item1[key2];
                    Object.keys(item2).forEach(key3 => {
                        const item3 = item2[key3];
                        if (item3.find(it => it === vehicle) && !item3.find(it => it !== vehicle && it.dispAttr.draw === true)){
                            checked1 = true;
                            checked2 = true;
                            delete gsth[key3];
                            this.$set(this, 'groupSelectedThree', gsth);
                        }
                    });
                    Object.keys(item2).forEach(key3 => {
                        if(key3 in gsth){
                            checked2 = false;
                        }
                    })
                    if (checked2) {
                        delete gsth[key2];
                        this.$set(this, 'groupSelectedThree', gsth);
                    }
                });
                Object.keys(item1).forEach(key2 => {
                    if(key2 in gsth){
                        checked1 = false;
                    }
                });
                if (checked1) {
                    delete gsth[key1];
                    this.$set(this, 'groupSelectedThree', gsth);
                }
            });
            if (!!vehicle.routeid && !this.groupSelectedThree.hasOwnProperty(vehicle.fullroutename)) {
                this.selectedRoutes.delete(vehicle.routeid);
            }
            this._drawRoutes(Array.from(this.selectedRoutes));
        }
    },
    vehiCheck({orgname}, checked){
        var n = 0;
        if (!!checked){
            n = 1;
        } else {
            n = _vehicles.filter( v=>{
                return ( 
                            (v.orgname == orgname) 
                         && (!!v.dispAttrs?.draw)
                        );
            }).length;
        }
        if ( n > 0 ){
            this.groupSelectedTwo[orgname] = true;
        } else if(!!this.groupSelectedTwo[orgname]){
            delete this.groupSelectedTwo[orgname];
        }
        this.$forceUpdate();
    },
    async search(s){
        const self = this;
        return new Promise((resolve, reject)=>{
            (new Promise((_resolve)=>{ //for wait loading
                var n = 0;
                const _wait = ()=>{
                    if ( n > 100){
                        reject("no data");
                    }
                    if (_vehicles.length > 0){
                        _resolve();
                    } else {
                        n++;
                        setTimeout(_wait, 300);
                    }
                }   //_wait
                _wait();
            })).then(async ()=>{
                const id = ((typeof s === 'string') || (s instanceof String)) ? s : s.id;
                const i = _vehicles.findIndex( v=>{ return v.id===id;} );
                console.log("search", id, i);
                await self.$refs["nMap"].ready();
                this.clearAll();
                if ( i > -1 ){
                    const ve = _vehicles[i];
                    ve.dispAttrs = {
                        draw: true,
                        tracking: false,
                        at: (new Date()).getTime()
                    };
                    this.expand[ve.orgname] = true;
                    this.groupSelectedTwo[ve.orgname] = true;
                    this.$forceUpdate();
                    resolve(ve);
                } else {
                    reject("no found #" + id);
                }
            }).catch((e)=>{
                console.log("ERR (search)", e);
                reject("err searching");
            });
        });
    },  // search
    set(q, val){
        const self = this;
        (async()=>{
            await self.$refs["nMap"].ready();
            switch(q){
                case "track":
                    await self.drawTrack(val.vehicle, val.start, val.end);
                    break;
                case "flag":
                    self.placeFlag(val);
                    break;
                case "fly":
                    self.flyToCoordinates(val.lat, val.lon, 17);
                    break;
                case "popup":
                    var i = _vehicles.findIndex(v=>{return (v.id === val.vehicle.id);});
                    if ( i < 0 ){
                        console.log("no vehicle #", val.vehicle);
                        return false;
                    }
                    const vehicle = _vehicles[i];
                    self.popupShow(vehicle, val.point);
                    break;
                default:
                    return false;
            }
        })();
    }   //set
  },    //methods
  watch: {
      isActive(val){
          if (!!val){
              const map = this.$refs['nMap'];
              if (!!map) {
                setTimeout(function(){map.update();}, 300);
            }
          }
      }
  }
};
</script>

<style lang="scss">
.jet-map-conte{
    padding: 0 !important;
    max-width: none;
    height: calc(100vh - 148px);
    & .v-list.mt-vehicles-tree{
        overflow-y: scroll; 
        height: calc(100% - 80px);
        & .v-list-item.v-list-group__header{
            min-height: 32px !important;
            & .v-list-item__title{
                display: flex;
                align-items: center;
                & .v-icon{
                    margin-right: 0.5rem;
                }
            }
        }
        & .item__title {
          text-overflow: ellipsis;
          overflow: hidden;
        }
        
        & .v-list-group__header__append-icon {
          min-width: 1rem;
          & .v-icon {
            font-size: 1.25rem !important;
          }
        }
    }
    & .split.map_splitter {
        & .gutter.gutter-vertical {
            display: block !important;
        }
    }
}
</style>