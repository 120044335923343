<template>
  <div
    :id="container"
    class="map"
    style="position:relative; width: 100%; height: 100%;"
  ></div>
</template>

<script>
import Mapbox from 'mapbox-gl';

//Mapbox.accessToken = 'pk.eyJ1IjoiYW50b25rIiwiYSI6ImNraXBvNmNrYzFtbzIyc2xiaHNzZ2lramEifQ.DTIkRnrbvr_8Fp1ZD_N5Iw';  by kih.ru
Mapbox.accessToken = 'pk.eyJ1IjoidmljdG9yejI4IiwiYSI6ImNrcTkzaGc5NTBtOHIyd29hb3k5ZTJiYjIifQ.ZKSNuoB3jwHBODgadFjOQQ';
const MBX_STYLE = 'mapbox://styles/victorz28/ckq94465f0wep17q30hnzw1t5';
const MAP_INSTANCES = {};

export default {
  name: 'JetMap',
  props: {
    name: {
      type: String,
      required: true
    },
  },
  data(){
      return {
          container: 'map-unnamed'
      };
  },
  created(){
      this.container = `map-${this.$props.name}-${(new Date()).getTime()}`;
  },
  computed: {
    map(){
        return MAP_INSTANCES[this.container];
    }
  },
  mounted(){
        const self = this;
        var _map = MAP_INSTANCES[this.container];
        console.log('map at: ', this.container, (!!_map) ? 'found' : 'NEW', _map);
        
        if ( !(!!_map) ){
            this.$nextTick(()=>{
                const node = $(`#${ self.container }`).get(0);
                _map = new Mapbox.Map({
                    container: node,
                    style: MBX_STYLE,
                    center: [
                        38.97603,
                        45.04484,
                    ],
                    zoom: 8,
                    maxZoom: 24,
                    pitchWithRotate: false,
                    preserveDrawingBuffer: true
                });
                _map.on('load', function(e){
                    console.log('map loaded', e);
                    MAP_INSTANCES[self.container] = _map;
                    window._map = _map;    //TODO: del
                    self.$emit('onload', _map);
                });
            });
        } else {
            this.$emit('onload', _map);
        }
  },
  destroyed(){
      if (!!this.map){
          this.map.remove();
          delete this.map;
      }
  },
  methods: {
    /**
     * Создание либо установка новых данных в источник
     *
     * @param {String} sourceId Имя истоничка
     * @param {{
     *   type: String,
     *   features: Array<{
     *      type: String,
     *      geometry: {
     *        type: String,
     *        coordinates: Array<number>,
     *      },
     *      properties: Object|*,
     *   }>,
     * }|*} sourceData Данные источника
     * @param {Object} options Дополнительные параметры слоя
     */
    createOrDataSource(sourceId, sourceData, options = {}){
        var src = this.map.getSource(sourceId);
        if (!!src) {
            src.setData(sourceData);
        } else {
            this.map.addSource(
                sourceId,
                Object.assign(options, {
                  type: 'geojson',
                  data: sourceData,
                }),
            );
        }
    },

    /**
     * Создает слой, если такового не было
     *
     * @param {String} layerId Идентификатор слоя
     * @param {{
     *   id: String,
     *   type: String,
     *   source: String,
     *   paint: *|Object,
     *   layout: *|Object,
     * }} layerData Данные слоя
     */
    createOrDataLayer(layerId, layerData) {
        var l = this.map.getLayer(layerId);
        if (!(!!l)) {
            // Если нет источника - создадим по-умолчанию
            if (!this.map.getSource(layerData.source)) {
              this.map.addSource(layerData.source, {
                type: 'FeatureCollection',
                features: [],
              });
            }
            this.map.addLayer(layerData);
        }
    },

    /**
     * Получение всех фич из источника
     * @param sourceId
     */
    getSourceFeatures(sourceId) {
      if (!!this.map.getSource(sourceId)) {
          return this.map.getSource(sourceId)._data?.features || [];
      }
      return null;
    },

    /**
     * Получние "сырого" объекта карты для низкоуровневых операций
     *
     * @returns {Mapbox.Map}
     */
    getRawMap() {
        return this.map;
    }
  }
};
</script>
<style lang="css">
    @import "https://api.mapbox.com/mapbox-gl-js/v2.0.0/mapbox-gl.css";
</style>
