<template>
  <v-card v-show="!!value"
          class="map_popup_card">
    <v-card-title @mousedown.stop.prevent="clickBehavior($event)">
        <div v-html="title"></div>
        <v-spacer />
        <v-btn icon @click="hide">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
    </v-card-title>
    <v-card-text v-if="has('text')"
                 v-html="html">
    </v-card-text>
    <v-card-text v-else-if="(!!value)">
        <v-progress-circular indeterminate color="primary" />
    </v-card-text>
    <v-card-actions v-if="has('time')">
        <v-icon small>mdi-clock-outline</v-icon>&nbsp;{{ time }}
    </v-card-actions>
  </v-card>
</template>

<script>
import { isEmpty } from "@/utils/utils";
const $moment = require('moment');

var _node;

export default {
  name: 'MapPopup',
    data() {
      return {
            x: 50,
            y: 50,
            html: null,
            title: null,
            value: false,
            time: null
      };
    },
    mounted(){
        this.$nextTick(()=>{
            _node = this.$el;
        });
    },
    methods: {
        has(q){
            switch(q){
                case "text":
                    return !isEmpty(this.html);
                case "time":
                    return !!this.time;
            }
        },
        set(info){
            if (!!info){
                this.html = info.text;
                this.title = info.title;
                this.time  = (!!info.time) ? $moment(info.time).format('DD.MM.YYYY HH:mm') : null;
                this.value = (new Date()).getTime();
                this.x = (info.point?.x || 40) + 10;
                this.y = (info.point?.y || 40) + 10;
                this.$nextTick(()=>{
                    this.setPosition(this.$el);
                });
            } else {
                this.value = false;
            }
        },
        setPopupData(html) {
          this.html = html;
        },
        setPopupTitle(title) {
          this.title = title;
        },

        clickBehavior(evt) {
            //const parent = evt.target.parentNode;

            //_node = parent;
            var parent = _node;

            let startX = evt.clientX;
            let startY = evt.clientY;
            let oLeft = parent.offsetLeft;
            let oTop = parent.offsetTop;
            const width = parent.offsetWidth;
            const pWidth = parent.parentNode.offsetWidth;
            const height = parent.offsetHeight;
            const pHeight = parent.parentNode.offsetHeight;
            this.x = Math.max(0, oLeft - (startX - evt.clientX));
            this.x = Math.min(this.x, pWidth - width - 10);
            this.y = Math.max(0, oTop - (startY - evt.clientY));
            this.y = Math.min(this.y, pHeight - height - 10);

            const onMouseMove = (event) => {
                oLeft = parent.offsetLeft;
                oTop = parent.offsetTop;
                this.x = Math.max(0, oLeft - (startX - event.clientX));
                this.x = Math.min(this.x, pWidth - width - 10);
                this.y = Math.max(0, oTop - (startY - event.clientY));
                this.y = Math.min(this.y, pHeight - height - 10);
                startX = event.clientX;
                startY = event.clientY;
                this.setPosition(parent);
            };

            const onMouseUp = ()=>{
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
                document.removeEventListener('mouseleave', onMouseUp);
                this.setPosition(parent);
            };
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
            document.addEventListener('mouseleave', onMouseUp);
        },  //clickBehavior

        setPosition(parent) {
            parent.style.left = this.x + 'px';
            parent.style.top = this.y + 'px';
        },
        show(point){
            this.x = point?.x || 50;
            this.y = point?.y || 50;
            this.value = (new Date()).getTime();
            this.$nextTick(()=>{
                this.setPosition(this.$el);
            });
        },
        hide(){
            this.value = false;
        }
    },  //
    watch: {
        value(val) {
            if (!(!!val)){
                this.html = null;
                this.title= null;
                this.time = null;
            }
            this.$emit('input', val);
        }
    }   //watch
};
</script>

<style lang="scss" scoped>
.v-card {
    position: absolute;
    left: 10px; 
    top: 20px;
    width: 450px;
    background: #fffdee;
    font-size: 0.9rem;
    &__title, &__text, &__actions{
        font-size: 0.85rem;
        padding: 0.5rem;
        line-height: 1.115;
    }
    &__title{
        cursor: move;
        border-bottom: 1px solid rgba(0,0,0,0.28);
        justify-content: space-between;
    }
    &__actions{
        border-top: 1px solid rgba(0,0,0,0.28);
    }
}    
</style>
