<template>
    <div class="jet-input jet-input-string" v-show="vis">
        <v-text-field
          type="text"
          v-model="value"
          :disabled="dis"
          :rules="rules"
          :label="label"
        >
            <template v-slot:append-outer>
                <jet-input-btn />
            </template>
        </v-text-field>
    </div>
</template>

<script>
import JetInputBase from '@/components/editors/JetInputBase';
import JetInputBtn from '@/components/editors/JetInputBtn';

export default {
    name: 'JetInputString',
    extends: JetInputBase,
    components: {
        JetInputBtn
    },
    data(){
          return {
              old: this.$attrs.value
          };
    },
    computed: {
        value: {
            get(){
                return this.$attrs.value;
            },
            set(val){
                this.$attrs.value = val;
                this.$emit('input', val);
            }
        }
    }
};
</script>
