var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"track-j",attrs:{"fluid":""}},[(!_vm.player.show)?[_c('v-row',{staticClass:"track-j__head"},[_c('v-col',{staticClass:"py-0"},[_vm._v(" Журнал событий телеметрии "),(_vm.data && _vm.data.length > 0)?_c('span',[_vm._v(" | "+_vm._s(_vm.data.length)+" записей ")]):_vm._e()]),_c('v-spacer'),_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white","disabled":_vm.data.length === 0},on:{"click":_vm.showPlayer}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}],null,false,1884164465)},[_vm._v(" Запустить анимацию ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"},on:{"click":_vm.minimizeJournal}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-chevron-"+_vm._s(_vm.minimized ? 'up' : 'down')+" ")])],1)]}}],null,false,2269904656)},[_vm._v(" "+_vm._s(_vm.minimized ? 'Развернуть' : 'Свернуть')+" журнал ")])],1)],1)]:_vm._e(),(_vm.player.show)?[_c('v-layout',{staticClass:"primary",staticStyle:{"height":"44px"},attrs:{"align-center":""}},[_c('v-flex',{staticClass:"text-no-wrap",attrs:{"shrink":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""},on:{"click":_vm.stopAnimation}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-stop")])],1)]}}],null,false,4194922652)},[_vm._v(" Остановить проигрывание ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""},on:{"click":_vm.playOrPauseAnimation}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-"+_vm._s(_vm.player.isPlay ? 'pause' : 'play')+" ")])],1)]}}],null,false,1382526442)},[_vm._v(" "+_vm._s(_vm.player.isPlay ? 'Приостановить' : 'Запустить')+" анимацию ")]),_c('v-tooltip',{attrs:{"top":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","outlined":"","small":""},on:{"click":_vm.incOrDecAnimSpeed}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.player.isIncreased ? '/' : 'x')+" 10 ")])]}}],null,false,109500021)},[_vm._v(" "+_vm._s(_vm.player.isIncreased ? 'Уменьшить' : 'Увеличить')+" скорость ")])],1),_c('v-flex',{staticClass:"ml-4",attrs:{"shrink":""}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.player.posTime || '')+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-flex',_vm._g(_vm._b({staticClass:"ml-4 py-1 fill-height",staticStyle:{"position":"relative"},on:{"mousemove":_vm.onTimelineMouseMove}},'v-flex',attrs,false),on),[_c('div',{staticClass:"fill-height",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","left":"0","top":"0","width":"100%","height":"100%"},on:{"click":_vm.onTimelinePosChange}},[_c('div',{ref:"timeline_percent",staticClass:"timeline_percent",style:(_vm.timelinePercentStyle)})]),_c('div',{ref:"timeline",staticClass:"timeline_graph fill-height"})])])]}}],null,false,2672958469)},[_vm._v(" "+_vm._s(_vm.player.timelineTimeTooltip)+" ")]),_c('v-flex',{attrs:{"shrink":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""},on:{"click":_vm.hidePlayer}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,3685792495)},[_vm._v(" Закрыть плеер ")])],1)],1)]:_vm._e(),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.minimized),expression:"!minimized"}],staticClass:"track-j__main"},[(!_vm.has('data'))?_c('v-col',{staticClass:"text-center pt-6",attrs:{"cols":"12"}},[_vm._v(" Необходимо выбрать трек для ТС ")]):_c('v-col',{staticClass:"track-j__data",attrs:{"cols":"12"}},[_c('v-data-table',{ref:"table",staticClass:"track-j__table",attrs:{"dense":"","fixed-header":"","disable-sort":"","headers":_vm.headers,"items":_vm.data,"item-key":"time","items-per-page":-1,"item-class":_vm.getColour,"single-select":"","disable-pagination":"","hide-default-footer":""},on:{"item-selected":_vm.selRow,"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._vehicleStatus(item))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.time, 'DD.MM.yyyy HH:mm:ss'))+" ")]}},{key:"item.speed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.speed))+" км/ч ")]}}])}),_c('div',{staticClass:"track-j__stats"},[_c('div',{staticClass:"journal__statistics_title"},[_vm._v("Гос. номер")]),_c('div',{staticClass:"journal__statistics_value"},[_vm._v(_vm._s(_vm.statistics.govNum)+" ")]),_c('div',{staticClass:"journal__statistics_title"},[_vm._v(" | расст.:")]),_c('div',{staticClass:"journal__statistics_value"},[_vm._v(_vm._s(_vm.statistics.distance)+" ")]),_c('div',{staticClass:"journal__statistics_title"},[_vm._v(" | время в пути:")]),_c('div',{staticClass:"journal__statistics_value"},[_vm._v(_vm._s(_vm.statistics.motorTime)+" ")]),_c('div',{staticClass:"journal__statistics_title"},[_vm._v(" | время стоянки:")]),_c('div',{staticClass:"journal__statistics_value"},[_vm._v(_vm._s(_vm.statistics.stopTime)+" ")]),_c('div',{staticClass:"journal__statistics_title"},[_vm._v(" | период:")]),_c('div',{staticClass:"journal__statistics_value"},[_vm._v(_vm._s(_vm.statistics.period)+" ")]),_c('div',{staticClass:"journal__statistics_title"},[_vm._v(" | сред.:")]),_c('div',{staticClass:"journal__statistics_value"},[_vm._v(_vm._s(_vm.statistics.averageSpeed)+" ")]),_c('div',{staticClass:"journal__statistics_title"},[_vm._v(" | макс:")]),_c('div',{staticClass:"journal__statistics_value"},[_vm._v(_vm._s(_vm.statistics.maxSpeed)+" ")])])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }