<template>
  <v-card class="mx-auto">
    <v-dialog
      persistent
      v-model="isShowFilter"
      max-width="550"
    >
      <v-card>
        <v-card-title>
          Фильтрация
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
                <span class="body-1 font-weight-bold">
                  Статус связи
                </span>

              <v-row>
                <v-col cols="6">
                  <v-checkbox
                    hide-details
                    v-model="filterForm.statuses.fail2to24"
                    type="checkbox"
                    label="Потеря связи с датчиком от 2 до 24 часов"
                  ></v-checkbox>

                  <v-checkbox
                    hide-details
                    v-model="filterForm.statuses.fail24to72"
                    type="checkbox"
                    label="Потеря связи с датчиков от 24 до 72 часов"
                  ></v-checkbox>

                  <v-checkbox
                    hide-details
                    v-model="filterForm.statuses.failUp72"
                    type="checkbox"
                    label="Потеря связи с датчиком свыше 72 часов"
                  ></v-checkbox>
                </v-col>

                <v-col cols="6">
                  <v-checkbox
                    hide-details
                    v-model="filterForm.statuses.inState"
                    type="checkbox"
                    label="На связи"
                  ></v-checkbox>

                  <v-checkbox
                    hide-details
                    v-model="filterForm.statuses.noData"
                    type="checkbox"
                    label="Нет данных"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
                <span class="body-1 font-weight-bold">
                  Статус движения
                </span>

              <v-row>
                <v-col cols="6">
                  <v-checkbox
                    hide-details
                    v-model="filterForm.moving.move"
                    type="checkbox"
                    label="Движется"
                  ></v-checkbox>

                  <v-checkbox
                    hide-details
                    v-model="filterForm.moving.stop"
                    type="checkbox"
                    label="Остановка"
                  ></v-checkbox>
                </v-col>

                <v-col cols="6">
                  <v-checkbox
                    hide-details
                    v-model="filterForm.moving.parking"
                    type="checkbox"
                    label="Стоянка"
                  ></v-checkbox>

                  <v-checkbox
                    hide-details
                    v-model="filterForm.moving.blocked"
                    type="checkbox"
                    label="Заблокирован"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    hide-details
                    v-model="filterForm.moving.alertButton"
                    type="checkbox"
                    label="Тревожная кнопка"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="clearFilterForm">
            Очистить
          </v-btn>

          <v-spacer/>

          <v-btn @click="closeFilterForm">
            Отмена
          </v-btn>

          <v-btn color="primary" @click="applyFilter">
            Применить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text>
      <v-layout align-center>
        <v-text-field
          clearable 
          clear-icon="mdi-close"
          hide-details
          dense
          label="Фильтрация"
          placeholder="Перевозчик, рег.номер ТС"
          :disabled="loading"
          @focusin="openedSearch = true"
          @focusout="openedSearch = false"
          @change="applyTextFilter($event)"
          @click:clear="applyTextFilter(null)">
        </v-text-field>
        <v-menu bottom
                offset-y
                content-class="map-setts-menu">
            <template #activator="{ on, attrs }">
                <v-btn plain
                       tile
                       text
                       elevation="0"
                       v-on="on">
                    <v-icon>mdi-menu</v-icon>
                </v-btn>
            </template>
            <v-list subheader dense>
                <v-list-item v-on:click="onSelectAll(2)">
                    <v-list-item-icon>
                        <v-icon>mdi-bus-multiple</v-icon>
                    </v-list-item-icon>
                    <v-badge v-if="(has('totals')&&(totals.all > 0))"
                             inline
                             color="success"
                             :content="totals.all">
                        Выбрать ВСЕ ТС
                    </v-badge>
                    <div v-else>Выбрать ВСЕ ТС</div>
                </v-list-item>
                <v-list-item v-on:click="onSelectAll(1)">
                    <v-list-item-icon>
                        <v-icon>mdi-bus-clock</v-icon>
                    </v-list-item-icon>
                    <v-badge v-if="(has('totals')&&(totals.planned > 0))"
                             color="success"
                             inline
                             :content="totals.planned">
                        Выбрать только запланированные
                    </v-badge>
                    <div v-else>Выбрать только запланированные</div>
                </v-list-item>
                <v-list-item v-on:click="clearTracks">
                    <v-list-item-icon>
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-list-item-icon>
                    Убрать треки с карты
                </v-list-item>
                <v-list-item v-on:click="showFilterMenu">
                    <v-list-item-icon>
                        <v-icon>mdi-format-list-checks</v-icon>
                    </v-list-item-icon>
                    Дополнительные фильтры
                </v-list-item>
                <v-divider />
                <v-subheader>Группировка ТС</v-subheader>
                <v-list-item-group>
                    <v-list-item
                      v-for="group in groups"
                      :key="group.mode"
                      v-on:click="onGroup(group)"
                      class="pl-8">
                        {{ group.name }}
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
const modes = {
  GRP_NONE: 0,
  // По перевозчикам
  GRP_BY_CARRIERS: 'GRP_BY_CARRIERS',
  // По перевозчикам-маршрутам
  GRP_BY_CARRIERS_ROUTES: 'GRP_BY_CARRIERS_ROUTES',
  // По маршрутам-перевозчикам
  GRP_BY_ROUTES_CARRIERS: 'GRP_BY_ROUTES_CARRIERS',
  // По маршрутам-выездам
  GRP_BY_ROUTES_DEPS: 'GRP_BY_ROUTES_DEPS',
  // По МО-перевозчикам
  GRP_BY_ARS_CARRIERS: 'GRP_BY_ARS_CARRIERS',
  // По группам
  GRP_BY_GRPS_CARRIERS: 'GRP_BY_GRPS_CARRIERS',
};

export default {
  props: {
    // Идентификатор загрузки
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    totals: {
        type: Object,
        required: false,
        default: null
    }
  },
  name: 'CarrierListHeader',
  inject: [
        'changeGroup', 
        'changeFilter',
        'selectAll', 
        'clearAll'
  ],
  data() {
    return {
      // Флаг показа всех ТС
      showAll: 0,  /* "0" - none, "1" - planing, "2" - all-all */
      // Флаг что всё загружается
      showAllLoading: false,
      // Варианты группировок
      groups: [
        { name: 'По перевозчикам', mode: modes.GRP_BY_CARRIERS },
        { name: 'По перевозчикам-маршрутам', mode: modes.GRP_BY_CARRIERS_ROUTES },
        { name: 'По маршрутам-перевозчикам', mode: modes.GRP_BY_ROUTES_CARRIERS },
        // TODO: Убрано в связи с тем что еще не реализовано (для показа)
        // {name: 'По маршрутам-выездам', mode: modes.GRP_BY_ROUTES_DEPS},
        // {name: 'По МО-перевозчикам', mode: modes.GRP_BY_ARS_CARRIERS},
        { name: 'По группам', mode: modes.GRP_BY_GRPS_CARRIERS },
      ],
      // Показ диалога фильтра
      isShowFilter: false,
      // Флаг, указывающий что поиск в фокусе
      openedSearch: false,
      // Объект формы фильтрации
      filterForm: {
        // Текст фильтрации
        text: '',
        // Статусы ТС
        statuses: {
          inState: true,
          fail2to24: true,
          fail24to72: true,
          failUp72: true,
          noData: true,
        },
        // Статус движения
        moving: {
          // Движется
          move: true,
          // Остановка
          stop: true,
          // Стоянка
          parking: true,
          // Заблокирован
          blocked: true,
          // Тревожная кнопка
          alertButton: false,
        },
      },
      // Текущие параметры фильтра
      currentFilter: {},
    };
  },
  methods: {
    // Очистка всех треков
    clearTracks() {
      // ebus.$emit(CarrierUtils.events.hideAllTracks);
      this.showAll = 0;
      this.clearAll();
    },
    // Показать меню фильтрации
    showFilterMenu() {
      this.isShowFilter = !this.isShowFilter;
    },
    // Очистка формы фильтрации
    clearFilterForm() {
      this.filterForm = {
        text: '',
        statuses: {
          inState: true,
          fail2to24: true,
          fail24to72: true,
          failUp72: true,
          noData: true,
        },
        moving: {
          move: true,
          stop: true,
          parking: true,
          blocked: true,
          alertButton: false,
        },
      };
    },
    // Закрытие формы фильтрации
    closeFilterForm() {
      this.isShowFilter = false;

      this.filterForm = _copy(
        this.currentFilter.hasOwnProperty('text') ? this.currentFilter : this.filterForm,
      );
    },
    // Применение фильтрации по тексту
    applyTextFilter(query) {
      this.filterForm.text = query;

      this.applyFilter();
    },
    // Выполненение фильтрации
    applyFilter() {
      const currentFilter = _copy(this.filterForm);

      let statusesFalse = true;
      let movingFalse = true;

      Object.keys(currentFilter.statuses).forEach(sKey => {
        statusesFalse = statusesFalse && !currentFilter.statuses[sKey];
      });

      Object.keys(currentFilter.moving).forEach(mKey => {
        movingFalse = movingFalse && !currentFilter.moving[mKey];
      });

      if (statusesFalse && movingFalse) {
        this.currentFilter = {
          // Текст фильтрации
          text: '',
          // Статусы ТС
          statuses: {
            inState: true,
            fail2to24: true,
            fail24to72: true,
            failUp72: true,
            noData: true,
          },
          // Статус движения
          moving: {
            // Движется
            move: true,
            // Остановка
            stop: true,
            // Стоянка
            parking: true,
            // Заблокирован
            blocked: true,
            // Тревожная кнопка
            alertButton: false,
          },
        };
      } else {
        this.currentFilter = _copy(this.filterForm);
      }

      this.changeFilter(this.currentFilter);

      this.isShowFilter = false;
    },
    // Выбор типа группировки
    onGroup(group) {
      this.$emit('group', group);
      this.showAll = 0;
      this.clearTracks();
      this.changeGroup(group.mode);
    },
    /**
     * Показать все ТС
     * @param {integer} all - show/hide all/planing vehicles
     */ 
    onSelectAll(all) {
        switch (all){
            case 1:
                this.showAll = (this.showAll === 1) ? 0 : 1;
                break;
            case 2:
                this.showAll = (this.showAll === 2) ? 0 : 2;
                break;
            default:
                this.showAll = 0;
        }
        this.selectAll(this.showAll);
    },
    has(q){
        switch(q){
            case "totals":
                return !!this.totals;
        }
    }
  }
};
</script>
<style lang="scss" scoped>
    .map-setts-menu{
        
    }
    .map-list-head{
        white-space: nowrap;
        overflow: hidden;
        & .v-btn{
            & .v-icon{
                color: #999;
                font-size: 1.25rem;
            }
            &:hover{
                & .v-icon{
                    color: var(--v-primary-base);
                }
            }
        }
    }
</style>