<template>
  <div>
    <v-app-bar app clipped-left flat color="primary" light dark class="jet-app-bar">
      <v-btn text icon @click="$emit('navshow')">
        <jet-svg width="16" height="16" xref="#ico-bars"/>
      </v-btn>
      <div class="jet-left-header">{{ appName }}</div>
      <v-spacer></v-spacer>
      <div class="right-header">{{ userName }}</div>
      <v-btn class="jet-notify-btn" text icon title="Уведомления" @click="dialogNotification = !dialogNotification">
        <v-badge :value="countNotification" overlap dot color="red">
          <jet-svg width="18" height="18" xref="#ico-bell" />
        </v-badge>
      </v-btn>
      <v-btn text icon title="Выход" @click="logout">
        <jet-svg width="18" height="18" xref="#sign-out"/>
      </v-btn>
    </v-app-bar>
    <NotificationShort
      ref="notificationDrawer"
      :show="dialogNotification"
      @close="dialogNotification = false"
    ></NotificationShort>
  </div>
</template>

<script>
import JetSvg from '@/components/JetSvg';
import JetSearchField from "@/components/JetSearchField";
import NotificationShort from "./dev/modules/notifications/components/NotificationShort";
import OtherService from "@/services/OtherService";

var WS_URI = {
    servers: process.env.VUE_APP_BACKEND_NATS_SERVER,
    user: process.env.VUE_APP_BACKEND_NATS_USERNAME,
    pass: process.env.VUE_APP_BACKEND_NATS_PASSWORD
};

const _TM_NOTIFY = 5*60*1000;

export default {
  name: 'JetAppBar',
  components: {
    JetSvg,
    JetSearchField,
    NotificationShort,
  },
  data: () => ({
    dialogNotification: false,
    countNotification: 0,
    atNotify: 0,
    codec: null,
    nats: null,
    sids: [],
    search: '',
  }),
  computed: {
    userName() {
      return this.$store.getters['profile/getName'];
    },
    appName() {
      let s = this.$store.getters['profile/orgName'];
      return $utils.isEmpty(s)
        ? this.$store.getters['branding/get']('brand.client.name')
        : s;
    },
  },
  created() {
    this.getUnreadCount();
    setTimeout(this.connectNats, 10000);
  },
  destroyed() {
    this.disconnectNats();
  },
  methods: {
    logout() {
      this.$store.dispatch('profile/logout');
    },
    async getUnreadCount() {
        const at = (new Date()).getTime();
        if ((at - this.atNotify) < _TM_NOTIFY) {
            return; //don`t quering by error
        }
        this.atNotify = at;
        try {
            this.countNotification = await OtherService.getUnreadCount();
        }catch(e){
            console.log('ERR (countNotification)', e);
            this.countNotification = -1;
        }
    },
    async connectNats() {
      if (!this.nats) {
        this.codec = await jet.http.getJSONCodec();
        this.nats = await jet.http.getNats(WS_URI);

        if (this.nats) {
          const userId = this.$store.state.auth.subject.id;
          const s = this.nats.subscribe('PUBLIC.kigat.eventMessaging.count.' + userId);
          (async ()=>{
            for await (const m of s) {
              try{
                var msg = codec.decode(m.data);
                this.onNatsMessageReceive(msg);
              } catch(e){
                console.log('ERR (on_telemetry):', e);
              }
            }
          })();
          this.sids.push(s);
        }
      }
    },
    disconnectNats() {
      if (this.nats) {
        this.sids.forEach(s => s.unsubscribe());
        this.sids = [];
        this.nats.disconnect();
        this.nats = null;
      }
    },
    onNatsMessageReceive(msg) {
      console.log('APPBAR (msg)', msg);
      this.getUnreadCount();
      
      this.$refs.notificationDrawer.newExist = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.jet-app-bar {
  & .v-input {
    font-size: 0.8rem;
  }

  & .jet-left-header {
    max-width: 30rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
